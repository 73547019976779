const id = 
    {   
        searchBy: "Cari Berdasarkan : ",
        title : "Klien API Server",
        searchPlaceHolder : "Cari",
        clientDetail : "Detail Klien",
        deleteingData : "Menghapus Data",
        apiClientDeleted : "Klien API sudah terhapus!",
        errorDeleting : "Terjadi kesalahan server",
        noApi : "Tidak ada klien API yang dipilih",
        confirmDelete : "Apakah anda yakin ingin menghapus klien API yang dipilih?",
        yes : "Ya",
        no : "Tidak",
        delete : "Hapus",
        add : "Tambah",
    }
export default id