import React, { Fragment } from 'react';
import { Descriptions, Card, Menu, Popconfirm, message  } from 'antd';
import { RouterConstant } from '../../../enum/routerConstant';
import AddButton from '../../addButton';
import PageTitle from '../../pageTitle';
import backlogo from '../../../assets/icon/back-icon.svg'
import { Col, Row, Button } from 'antd';
import * as langService from '../../../enum/language/apiClient/detail';
import * as apiClientService from '../../../acessApi/SystemAdmin/apiClientService';
import './index.css';
import { Link } from 'react-router-dom';
import { RoleChecker } from '../../../utility/RoleChecker';

class SourceDetail extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            pageDetail : null,
            title: null,
            visible: false,
            data: null,
            lang : {}

        };
    }
    async getData(){
        const id = this.props.match.params.id;
        let ApiClientData = []
        ApiClientData = await apiClientService.getClientDetail(id)
        if (ApiClientData != null) {
            const data =  ApiClientData.data.nexsoft_message.payload.data.additional_info
            this.setState({
            data : ApiClientData, 
            title : 
            <PageTitle TitleText={data.client_name} titleColor='black'/>,
            pageDetail : 
                <Descriptions>
                    <Descriptions.Item label={this.state.lang.username}>{data.username}</Descriptions.Item>
                    <Descriptions.Item label={this.state.lang.principalId}>{data.principal_id}</Descriptions.Item>
                    <Descriptions.Item label={this.state.lang.clientName}>{data.client_name}</Descriptions.Item>
                    {/* <Descriptions.Item label={this.state.lang.clientCode}>{data.code}</Descriptions.Item> */}
                    <Descriptions.Item label={this.state.lang.serverID}>{data.server_id}</Descriptions.Item>
                    <Descriptions.Item label={this.state.lang.email}>{data.email}</Descriptions.Item>
                    <Descriptions.Item label={this.state.lang.address}>{data.address}</Descriptions.Item>
                    <Descriptions.Item label={this.state.lang.phone}>{data.phone_number}</Descriptions.Item>
                    <Descriptions.Item label={this.state.lang.connectionInfo}>{data.connection_info}</Descriptions.Item>
                </Descriptions>
            },)
        }else{
            this.setState({
                pageDetail : <PageTitle TitleText={this.state.lang.noData} titleColor="black"/>,
            })
        }
    }

    goback(){
        window.history.back();
    }

    async componentDidMount(){
        this.getData()
        this.setState({
            lang : await langService.getLang(localStorage.getItem("language"))
        })
    }

    async resetServerId(){
        message.destroy();
        message.loading(this.state.lang.resetingMac, 0);
        const response = (await apiClientService.resetMacAddress((this.state.data).data.nexsoft_message.payload.data.additional_info));
        message.destroy();
        if (response.data.nexsoft_message.payload.data.error_code === "Oke") {
            message.success(this.state.lang.resetSuccess);
            this.setState({isFetching: false});
            this.componentDidMount()
        } else {
            message.error(this.state.lang.errorResetting);
            this.setState({isFetching: false});
            this.componentDidMount()
        }
    }

    
    render() {
        return (
            <Card className="api-client-detail-card">
                <Row className="api-client-detail-card-title">
                    <a onClick={() => { this.goback()}} >
                        <Col span={1} style={{lineHeight : '30px', textAlign: 'center'}}>
                        <img src={backlogo} alt="logoutLogo" style={{width: '18px'}}></img>
                        </Col>
                    </a>
                    <Col span={10}>
                        {this.state.title}
                    </Col>
                    <Col span={13} style={{textAlign : 'right'}}>
                        {
                            (
                                RoleChecker() == true ?
                                <Fragment>
                                    <Popconfirm
                                    title={this.state.lang.confirmReset}
                                    onConfirm={() => this.resetServerId()}
                                    okText={this.state.lang.yes}
                                    cancelText={this.state.lang.no}
                                    >
                                        <Button style={{width: '135px', margin: '5px'}}>
                                            {this.state.lang.resetServer}
                                        </Button>
                                    </Popconfirm>
                                    <Link to={RouterConstant.SYSADMIN.SOURCE.changePassword + '/' + this.props.match.params.id } >
                                        <Button style={{width: '135px', margin: '5px'}}>
                                            {this.state.lang.changePassword}
                                        </Button>
                                    </Link>
                                    <Link to={RouterConstant.SYSADMIN.SOURCE.edit + '/' + this.props.match.params.id } >
                                        <Button style={{width: '135px', margin: '5px'}}>
                                            {this.state.lang.edit}
                                        </Button>
                                    </Link>
                                </Fragment>
                                : ""
                            )
                        }
                    </Col>
                </Row>
                {this.state.pageDetail}
            </Card>
        )
    }
}

export default SourceDetail;
