import React, { Fragment } from 'react';
import 'antd/dist/antd.css';
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col, 
  message
} from 'antd';
import { RouterConstant } from '../../../enum/routerConstant';
import PageTitle from '../../pageTitle';
import backlogo from '../../../assets/icon/back-icon.svg'
import * as langService from '../../../enum/language/apiClient/create';
import { Link } from 'react-router-dom';
import AddButton from '../../addButton';
import * as apiClientService from '../../../acessApi/SystemAdmin/apiClientService'
import {AccessChecker} from '../../../utility/AccessChecker'

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        confirmDirty: false,
        autoCompleteResult: [],
        lang : {}
    }
  }

  async componentDidMount(){
    await this.setState({
      lang : await langService.getLang(localStorage.getItem("language"))
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.postData(values)
      }
    });
  };

  async postData(data) {
    message.destroy();
    message.loading(this.state.lang.creatingApi, 0);
    const response = (await apiClientService.createApiClient(data));
    message.destroy();
    if (response.data.nexsoft_message.payload.data.error_code === "Oke") {
      message.success(this.state.lang.apiClientCreated);
      this.props.form.resetFields();
    } else {
      message.error(this.state.lang.errorCreating);
    }
  }

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(this.state.lang.passwordNotMatch);
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleWebsiteChange = value => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
    }
    this.setState({ autoCompleteResult });
  };

  goback(){
    window.history.back();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    AccessChecker()
    const formItemLayout = {
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    return (
      <Row>
        <Card style={{borderRadius : '10px', boxShadow :'5px 5px 20px #888888', minHeight: '600px', margin:'15px'}}>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Row style={{margin : '5px'}}>
              <a onClick={() => { this.goback()}} >
                <Col span={1} style={{lineHeight : '30px', textAlign: 'center'}}>
                  <img src={backlogo} alt="logoutLogo" style={{width: '18px'}}></img>
                </Col>
              </a>
              <Col span={10} style={{lineHeight : '30px'}}>
                <PageTitle TitleText={this.state.lang.title}/>
              </Col>
              <Col span={13} style={{textAlign : 'right'}}>
                {/* <Button onClick={() => { this.goback()} }
                disabled={this.state.isFetching} style={{ width: '135px', margin:"5px"}}>
                    Cancel
                </Button> */}
                <Button htmlType="submit" style={{backgroundColor : '#5493FF', color: 'white', width: '135px', margin:"5px"}}>
                    {this.state.lang.save}
                </Button>
              </Col>
            </Row>
            <Row style={{fontSize : '16px', fontWeight: 'bold', margin :'10px'}}>
              <Form.Item label={this.state.lang.username} >
              {getFieldDecorator('username', {
                  rules: [
                  {
                      required: true,
                      message: this.state.lang.usernameMessage,
                      pattern: new RegExp("^[a-zA-Z0-9 _-]{3,16}$"),
                  },
                  ],
              })(<Input />)}
              </Form.Item>
              <Form.Item label={this.state.lang.password} hasFeedback>
              {getFieldDecorator('password', {
                  rules: [
                  {
                      required: true,
                      message: this.state.lang.passwordMessage,
                  },
                  {
                      validator: this.validateToNextPassword,
                  },
                  ],
              })(<Input.Password />)}
              </Form.Item>
              <Form.Item label={this.state.lang.confirmPassword} hasFeedback>
              {getFieldDecorator('confirm', {
                  rules: [
                  {
                      required: true,
                      message: this.state.lang.confirmPasswordMessage,
                  },
                  {
                      validator: this.compareToFirstPassword,
                  },
                  ],
              })(<Input.Password onBlur={this.handleConfirmBlur} />)}
              </Form.Item>
              <Form.Item label={this.state.lang.clientName}>
              {getFieldDecorator('clientName', {
                  rules: [
                  {
                      required: true,
                      message: this.state.lang.clientNameMessage,
                  },
                  ],
              })(<Input />)}
              </Form.Item>
              {/* <Form.Item label={this.state.lang.clientCode}>
              {getFieldDecorator('clientCode', {
                  rules: [
                  {
                      required: true,
                      message: this.state.lang.clientCodeMessage,
                  },
                  ],
              })(<Input />)}
              </Form.Item> */}
              <Form.Item label={this.state.lang.email}>
              {getFieldDecorator('email', {
                  rules: [
                  {
                      type: 'email',
                      message: this.state.lang.emailError,
                  },
                  {
                      required: true,
                      message: this.state.lang.emailMessage,
                  },
                  ],
              })(<Input />)}
              </Form.Item>
              <Form.Item label={this.state.lang.connectionInfo}>
              {getFieldDecorator('connInfo', {
                  rules: [{ 
                    message: this.state.lang.connectionInfoMessage
                  }],
              })(<Input />)}
              </Form.Item>
              <Form.Item label={this.state.lang.address}>
              {getFieldDecorator('address', {
                  rules: [{ 
                    message: this.state.lang.addressMessage
                  }],
              })(<Input />)}
              </Form.Item>
              <Form.Item label={this.state.lang.phoneNumber}>
              {getFieldDecorator('phoneNumber', {
                  rules: [{ 
                    message: this.state.lang.phoneNumberMessage
                  }],
              })(<Input />)}
              </Form.Item>
            </Row>
          </Form>
        </Card>
      </Row>
    );
  }
}

const AddNewSource = Form.create({ name: 'add' })(RegistrationForm);

export default AddNewSource;
          