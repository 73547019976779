import * as baseApi from '../baseApi.js';
import * as headerFormat from '../../common/createNexsoftMessageHeader'
import * as download from '../SystemAdmin/logService'
import {apiUrl}  from "../urlConstant";
import moment from "moment";

export function countDataMonitoringDetail(id) {
    const url = apiUrl + '/monitoring/datamonitoring/view/count'
    const payload = {
        "header":{
           "id": parseInt(id)
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("COUNT"), payload)
      
}

export function getDataMonitoringDetail(id, page, limit) {
    const url = apiUrl + '/monitoring/datamonitoring/view/list'
    const payload = {
        "header":{
           "id": parseInt(id),
           "page":page,
           "limit":limit,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("LIST"), payload)
      
}

export function getDataMonitoringList(page, limit, filterBy) {
    const url = apiUrl + '/monitoring/datamonitoring/list'
    const payload = {
        "header":{
           "page":page,
           "limit":limit,
           "filter_by":filterBy,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("LIST"), payload)
    
}

export function countDataMonitoring(filterBy) {
    const url = apiUrl + '/monitoring/datamonitoring/count';
    const payload = {
        "header":{
            "filter_by":filterBy,
         }
    }
    return baseApi.postData(url, headerFormat.createHeader("COUNT"), payload)
}

export function downloadFile(uuid) {
    const url = apiUrl + '/monitoring/downloadfile';
    const payload = {
        "header":{
            "uuid": uuid,
         }
    }
    return download.postData(url, headerFormat.createHeader("DOWNLOAD"), payload)
}

export async function getSearchData(limit, page, data) {
    const url = apiUrl + '/monitoring/datamonitoring/search/list'
    const payload = {
        "header":{
           "page": page,
           "limit": limit,
           "data_type": data.data_type,
            "filename": data.file_name,
            "destination": data.destination,
            "sender" : data.sender,
            "range_from": data.date != null ? data.date[0] : "",
            "range_to": data.date != null ? data.date[1] : "",
            "status": data.status,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("SEARCH"), payload)
}

export async function countSearch(data) {
    const url = apiUrl + '/monitoring/datamonitoring/search/count'
    const payload = {
        "header":{
            "data_type": data.data_type,
            "filename": data.file_name,
            "destination": data.destination,
            "sender" : data.sender,
            "range_from": data.date != null ? data.date[0] : null,
            "range_to": data.date != null ? data.date[1] : null,
            "status": data.status,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("SEARCH"), payload)
}
