const en = 
    {   
        username: "Username",
        principalId : "Principal ID",
        clientName : "Nama Klien",
        clientCode : "API Client Code",
        serverID : "Server Id",
        email : "E-Mail",
        address : "Alamat",
        phone : "Nomor Telepon",
        connectionInfo : "Connection Info",
        noData : "Tidak Ada Data",
        resetingMac : "Mereset alamat server",
        resetSuccess : "Alamat server sudah di reset!",
        errorResetting : "Terjadi kesalahan server",
        resetServer : "Reset Server Id",
        changePassword : "Ganti Kata Sandi",
        edit : "Sunting",
        confirmReset : "Anda yakin ingin mereset server id?",
        yes : "Ya",
        no : "Tidak",
    }
export default en