import * as baseApi from '../baseApi.js';
import * as headerFormat from '../../common/createNexsoftMessageHeader'
import {apiUrl}  from "../urlConstant";

export async function updateClientData(data) {
    const url = apiUrl + '/monitoring/client/update'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            'id' : data.id,
            'username' : data.username,
            'password' : " ",
            'principal_id' : data.principal,
            'client_name' : data.clientName,
            // 'code' : data.clientCode,
            'email' : data.email,
            'connection_info' : data.connInfo,
            'address' : data.address,
            'phone_number' : data.phoneNumber,
            'record_meta_data' : {
                "last_updated_at": data.lastUpdated
            }
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("UPDATE"), payload)
}

export async function deleteApiClient(clientData) {
    const data = clientData.data.nexsoft_message.payload.data.additional_info
    const url = apiUrl + '/monitoring/client/delete'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            'id' : data.id,
            'username' : data.username,
            'password' : " ",
            'principal_id' : data.principal_id,
            'client_name' : data.client_name,
            'code' : data.code,
            'email' : data.email,
            'connection_info' : data.connection_info,
            'record_meta_data' : {
                "last_updated_at": data.record_meta_data.last_updated_at
            }
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("DELETE"), payload)
}


export async function changePassword(clientData, id, lastUpdated) {
    const url = apiUrl + '/monitoring/client/changepassword'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id')),
           "new_password" : clientData.password,
           "validate_password": clientData.confirm
        },
        "data": {
            "id": parseInt(id),
            "password": clientData.oldpassword,
            "record_meta_data": {
                "last_updated_at": lastUpdated
            }
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("UPDATE"), payload)
}

export async function resetMacAddress(clientData) {
    const url = apiUrl + '/monitoring/client/resetserverid'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        "data": {
            "id": clientData.id,
            "record_meta_data": {
                "last_updated_at": clientData.record_meta_data.last_updated_at
            }
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("RESET"), payload)
}

export async function deleteMultiple(clientData) {
    const url = apiUrl + '/monitoring/client/multipledelete'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'array_data' : clientData
    }
    return baseApi.postData(url, headerFormat.createHeader("DELETE"), payload)
}

export async function createApiClient(data) {
    const url = apiUrl + '/monitoring/client/create'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            'id' : null,
            'username' : data.username,
            'password' : data.password,
            'principal_id' : data.principal,
            'client_name' : data.clientName,
            // 'code' : data.clientCode,
            'email' : data.email,
            'address' : data.address,
            'phone_number' : data.phoneNumber,
            'connection_info' : (data.connInfo === undefined || data.connInfo === null ? "" : data.connInfo),
            'record_meta_data' : {
                "last_updated_at": ""
            }
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("CREATE"), payload)
}

export async function countSearch(search_by, key) {
    const url = apiUrl + '/monitoring/client/countsearch'
    const payload = {
        "header":{
           "search_by": search_by,
           "key": key,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("SEARCH"), payload)
}

export async function getSearchData(limit, page, search_by, key) {
    const url = apiUrl + '/monitoring/client/search'
    const payload = {
        "header":{
           "page": page,
           "limit": limit,
           "search_by": search_by,
           "key": key,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("SEARCH"), payload)
}

export async function getSourceListData(limit, page) {
    const url = apiUrl + '/monitoring/client/list'
    const payload = {
        "header":{
           "page":page,
           "limit":limit
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("LIST"), payload)
}

export function countTotalData() {
    const url = apiUrl + '/monitoring/client/count';
    const payload = {}
    return baseApi.postData(url, headerFormat.createHeader("COUNT"), payload)
      
}

export function getClientDetail(id) {
    const url = apiUrl + '/monitoring/client/view'
    const payload = {
        "header":{
            "id" : parseInt(id)
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("VIEW"), payload)
      
}