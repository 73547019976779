import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

class PageTitle extends React.Component {
    render() {
        return (
            <Title level={3} style={{color : this.props.titleColor}}>{this.props.TitleText}</Title>
        )
    }
}

export default PageTitle;
