import React, { Fragment } from 'react';
import { Card, Col, Row, Pagination, Popover, Icon, Button, Table, Input, Menu, Form, Select, DatePicker, message } from 'antd';
import { RouterConstant } from '../../../enum/routerConstant';
import SearchBar from '../../SearchBar';
import * as jobProcessService from '../../../acessApi/SystemAdmin/jobProcessService'
import PageTitle from '../../pageTitle';
import './index.css';
import { Link } from 'react-router-dom';
import StatusIcon from '../../../common/jobDetailStatusIcon'
import JobProcessText from './jobProcessGetSearchText';
import { Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.css';
import { size, toLower } from 'lodash';
import moment from "moment";
import * as apiClientService from '../../../acessApi/SystemAdmin/apiClientService';
import * as destinationGroupService from '../../../acessApi/SystemAdmin/destinationGroupService'
import * as dataTypeService from '../../../acessApi/SystemAdmin/dataTypeService';
const { Search } = Input;
const { RangePicker } = DatePicker;





class UploadMonitoringList extends React.Component {
  constructor(props) {
    super(props);
    this.searchBy = (
      <Menu>
        <Menu.Item>
          <div onClick={
            () => {
              this.setState({
                searchBy: 'filename'
              })
            }
          }>
            File Name
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={
            () => {
              this.setState({
                searchBy: 'datatype'
              })
            }
          }>
            Data type
          </div>
        </Menu.Item>
      </Menu>
    );
    this.columndata = [
      {
        width: 250,
        title: 'File Name',
        dataIndex: 'fileName',
        key: 'fileName',
        render: text => <p>{text}</p>,
      },
      {
        width: 75,
        title: 'Data Type',
        dataIndex: 'dataType',
        key: 'dataType',
        render: text => <p>{text}</p>,
      },
      {
        width: 50,
        title: 'Sender',
        dataIndex: 'sender',
        key: 'sender',
        render: text => <p>{text}</p>,
      },
      {
        width: 75,
        title: 'Destination',
        dataIndex: 'destination',
        key: 'destination',
        render: text => <p>{text}</p>,
      },
      {
        width: 60,
        title: 'Download Progress',
        dataIndex: 'downloadProgress',
        key: 'downloadProgress',
        render: text => <p>{text}</p>,
      },
      {
        width: 100,
        title: 'Status',
        dataIndex: 'downloadStatus',
        key: 'downloadStatus',
        render: text => <StatusIcon message={text} />,
      },
      {
        width: 200,
        title: 'Date Received',
        dataIndex: 'dateReceived',
        key: 'dateReceived',
        render: text => <p>{text}</p>,
      },
      {
        width: 40,
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Popover content={"Job detail"}>
              <Link to={RouterConstant.SYSADMIN.JOB.detail + '/' + record.key} key={record.key} >
                <Icon type="file-search" style={{ margin: '5px', color: "#7a7a7a" }} />
              </Link>
            </Popover>
          </span>
        ),
      },
    ];
    this.state = {
      jobProcessData: null,
      currentPage: 1,
      totalPages: null,
      jobData: null,
      totalData: null,
      itemPerPage: 10,
      dataCard: null,
      searching: false,
      searchBy: "filename",
      searchKey: null,
      filterBy: "all",
      show: false,
      data: {
        data_type: "",
        file_name: "",
        destination: "",
        sender: "",
        status: "",
        date: null,
      },
      sourceListData: null,
      GroupListData: null,
      dataTypeListData: null,
    };
  }

  async getData(pageLimit, currentPage, filterBy) {
    message.loading("Finding your data", 0)
    this.setState({
      jobProcessData: await jobProcessService.getDataMonitoringList(currentPage, pageLimit, filterBy)
    })
    this.mapData()
    message.destroy()
  }

  handleModal() {
    this.getFilterDataToLocalStorage()
    this.setState({ show: !this.state.show })
  }

  onPageChange = async page => {
    await this.setState({
      currentPage: page,
    });
    if (this.state.searching == true) {
      this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.data)
    } else {
      this.getData(this.state.itemPerPage, this.state.currentPage, this.state.filterBy)
    }
  };

  onShowSizeChange = async (current, pageSize) => {
    await this.setState({
      itemPerPage: pageSize,
      currentPage: 1
    });
    if (this.state.searching == true) {
      this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.data)
    } else {
      this.getData(this.state.itemPerPage, this.state.currentPage, this.state.filterBy)
    }
  }

  async mapData() {
    let result = []
    const data = await this.state.jobProcessData.data.nexsoft_message.payload.data.additional_info
    if (data != null) {
      data.map((results) => {
        result.push({
          key: `${results.id}`,
          fileName: `${results.file_name}`,
          dataType: `${results.data_type_name}`,
          sender: `${results.sender}`,
          destination: `${results.destination}`,
          downloadStatus: `${results.status}`,
          downloadProgress: `${results.downloader}` + " / " + `${results.receiver}`,
          dateReceived: `${results.record_meta_data.last_updated_at}`,
        });
      });
      this.setState({
        jobData: result
      })
    } else {
      this.setState({
        jobData: result
      })
    }
  }


  async searchClient(data) {
    await this.setState({
      totalData: await (await jobProcessService.countSearch(data)).data.nexsoft_message.payload.data.additional_info,
      currentPage: 1
    })
    await this.getSearch(this.state.itemPerPage, this.state.currentPage, data)
  }

  async getSearch(pageLimit, currentPage, data) {
    message.loading("Finding your data", 0)
    this.setState({ jobProcessData: await jobProcessService.getSearchData(pageLimit, currentPage, data) });
    this.mapData()
    message.destroy()
  }

  async componentDidMount() {
    await this.setState({
      totalData: await (await jobProcessService.countDataMonitoring(this.state.filterBy)).data.nexsoft_message.payload.data.additional_info
    })
    await this.getData(this.state.itemPerPage, this.state.currentPage, this.state.filterBy)
    this.getDataForClientSenderDropdown()
    this.getDataForGroupDestinationDropdown()
    this.getDataTypeForDataTypeDropdown()
    this.mapData()
  }

  handleFilter = async value => {
    console.log(this.state.currentPage)
    await this.setState({
      currentPage: 1,
      filterBy: value.key,
    })
    console.log('bcd', this.state.currentPage)
    this.componentDidMount()
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.setState({
        data: {
          data_type: values.data_type,
          file_name: values.file_name,
          destination: values.destination,
          sender: values.sender,
          status: values.status,
          date: values.date,
          show: !this.state.show
        }
      })
      this.handleSearchSubmit(values)
      this.setFilterDataToLocalStorage(values)
      this.handleModal()
    });
  };

  handleSearchSubmit = async value => {
    if (value === null || value === "") {
      this.setState({
        searching: false
      })
      this.componentDidMount()
    } else {
      this.searchClient(value)
      this.setState({
        searching: true
      })
    }

  }

  async getDataForClientSenderDropdown() {
    this.setState({ sourceListData: await apiClientService.getSourceListData(9999, 1) });
  }

  async getDataForGroupDestinationDropdown() {
    this.setState({ GroupListData: await destinationGroupService.getDestinationGroupListDataFromApi(1, 9999)});
  }

  async getDataTypeForDataTypeDropdown() {
    this.setState({ dataTypeListData: await dataTypeService.getDataTypeList(9999, 1)});
    console.log(this.state.dataTypeListData.data.nexsoft_message.payload.data.additional_info);
  }

  getClients() {
    console.log(this.state.sourceListData)
    if (this.state.sourceListData != null) {
      let clients = this.state.sourceListData.data.nexsoft_message.payload.data.additional_info
      return clients.map((clients) => {
        return <Select.Option key={clients.client_name} value={clients.id}>{clients.client_name}
        </Select.Option>;
      });
    }
  }

  getGroups() {
    console.log(this.state.GroupListData)
    if (this.state.GroupListData != null) {
      let groups = this.state.GroupListData.data.nexsoft_message.payload.data.additional_info
      return groups.map((groups) => {
        return <Select.Option key={groups.Group_name} value={groups.Id}>{groups.Group_name}
        </Select.Option>;
      });
    }
  }

  getDataType() {
    console.log(this.state.dataTypeListData)
    if (this.state.dataTypeListData != null) {
      let dataType = this.state.dataTypeListData.data.nexsoft_message.payload.data.additional_info
      return dataType.map((dataType) => {
        return <Select.Option key={dataType.Data_type} value={dataType.Data_type}>{dataType.Data_type}
        </Select.Option>;
      });
    }
  }

  setFilterDataToLocalStorage = value => {
    localStorage.setItem('data_type', value.data_type)
    localStorage.setItem('file_name', value.file_name)
    localStorage.setItem('destination', value.destination)
    localStorage.setItem('sender', value.sender)
    localStorage.setItem('status', value.status)
    localStorage.setItem('dateFrom', value.date != null ? value.date[0] : "")
    localStorage.setItem('dateTo', value.date != null ? value.date[1] : "")
  }

  async getFilterDataToLocalStorage() {
    let NewDateArray = null
    if (localStorage.getItem('dateFrom') != "" | localStorage.getItem('dateTo') != "") {
      NewDateArray = []
      NewDateArray.push(moment(localStorage.getItem('dateFrom')))
      NewDateArray.push(moment(localStorage.getItem('dateTo')))
    }
    this.setState({
      data: {
        data_type: localStorage.getItem('data_type'),
        file_name: localStorage.getItem('file_name'),
        destination: parseInt(localStorage.getItem('destination')),
        sender: parseInt(localStorage.getItem('sender')),
        status: localStorage.getItem('status'),
        date: NewDateArray,
      }
    })
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      data: {
        data_type: "",
        file_name: "",
        destination: 0,
        sender: 0,
        status: "all",
        date: null,
      },
    })
  }

  render() {
    const pagination = {
      position: 'top',
      defaultCurrent: this.state.currentPage,
      total: this.state.totalData,
      onChange: this.onPageChange,
      showSizeChanger: true,
      onShowSizeChange: this.onShowSizeChange,
      pageSizeOptions: ["10", "20", "30", "40"],
      current: this.state.currentPage,
    };

    const { getFieldDecorator } = this.props.form;





    return (
      <Fragment >
        <Row>
          <Card style={{ borderRadius: '10px', boxShadow: '5px 5px 20px #888888', minHeight: '800px', margin: '15px' }}>
            <Row style={{ margin: '5px' }}>
              <Col>
                <PageTitle TitleText="Job Process" />
              </Col>
            </Row>
            <Row >
              <div>
                <Button size='default' style={{ width: '10%' }} onClick={() => this.handleModal()}>Filter</Button>
                <Modal size='lg' centered keyboard
                  show={this.state.show} onHide={() => this.handleModal()}>
                  <Modal.Header style={{ textAlign: 'left', fontSize: '30px', borderBottom: '0 none' }} closeButton>Filter</Modal.Header>
                  <Modal.Body>
                    <Form
                      name="basic"
                      labelCol={{
                        span: 4,
                      }}
                      wrapperCol={{
                        span: 16,
                      }}
                      autoComplete="off"
                      onSubmit={this.handleSubmit}
                      onReset={this.handleReset}
                      style={{
                        fontSize: '25px'
                      }}
                    >
                      <Form.Item
                        label="Data Type"
                        name="Data Type"
                      >
                        {getFieldDecorator('data_type', {
                          initialValue: this.state.data.data_type
                        })(<Select showSearch
                          filterOption={(inputValue, option) =>
                            option.key != "" && toLower(option.key).includes(toLower(inputValue))
                          }>
                            <Select.Option key="" value="" selected="selected">Select Data Type</Select.Option>
                            {this.getDataType()}
                          </Select>)}
                      </Form.Item>

                      <Form.Item
                        label="File Name"
                        name="File Name"
                      >
                        {getFieldDecorator('file_name', {
                          initialValue: this.state.data.file_name
                        })(<Input />)}
                      </Form.Item>

                      <Form.Item
                        label="Destination"
                      >
                        {getFieldDecorator('destination', {
                          initialValue: this.state.data.destination,
                        })(
                          <Select showSearch
                          filterOption={(inputValue, option) =>
                            option.key != "" && toLower(option.key).includes(toLower(inputValue))
                          }>
                            <Select.Option key={0} value= {0} selected="selected">Select Destination</Select.Option>
                            {this.getGroups()}
                          </Select>
                        )}

                      </Form.Item>

                      <Form.Item
                        label="Sender">
                        {getFieldDecorator('sender', {
                          initialValue: this.state.data.sender,
                        })(
                          <Select showSearch
                          filterOption={(inputValue, option) =>
                            option.key != "" && toLower(option.key).includes(toLower(inputValue))
                          }
                          >
                            <Select.Option key={0} value={0} selected="selected">Select Sender</Select.Option>
                            {this.getClients()}
                          </Select>
                        )}

                      </Form.Item>

                      <Form.Item
                        label="Date Periode"

                      >
                        {getFieldDecorator('date', {
                          initialValue: this.state.data.date
                        })(<RangePicker size='large' style={{ width: '100%' }} />)}
                      </Form.Item>
                      <Form.Item
                        label="Status">
                        {getFieldDecorator('status', {
                          initialValue: this.state.data.status
                        })(
                          <Select>
                            <Select.Option value="done">Done</Select.Option>
                            <Select.Option value="inprogress">Inprogress</Select.Option>
                            <Select.Option value="all">All</Select.Option>
                          </Select>
                        )}
                      </Form.Item>
                      <Form.Item
                        wrapperCol={{
                          offset: 8,
                          span: 16,
                        }}
                      >
                      </Form.Item>
                      <div style={{ textAlign: 'center', width: '100%', padding: '0 120px' }}>
                        <Button htmlType="submit" size='large' style={{ backgroundColor: '#5493FF', color: 'white', width: '135px', margin: "5px", float: 'right' }}>
                          Save
                        </Button>
                        <Button htmlType="reset"size='large' style={{ backgroundColor: '#5493FF', color: 'white', width: '135px', margin: "5px", float: 'left' }} onReset={this.handleReset}>
                          Reset
                        </Button>
                      </div>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer style={{ borderTop: '0 none' }}>
                  </Modal.Footer>
                </Modal>
              </div>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Table
                dataSource={this.state.jobData}
                columns={this.columndata}
                pagination={pagination}
                onChange={this.handleTableChange}
                size="small"
              />
            </Row>
          </Card>
        </Row>
      </Fragment>
    )
  }
}

const FilterJobProcess = Form.create({ name: 'filter' })(UploadMonitoringList);

export default FilterJobProcess;
