import en from "./en"
import id from "./id"

// const templang = localStorage.getItem("language") === 'id' ? id : en;
let templang;
switch (localStorage.getItem("language")) {
    case "en":
        templang = en;
        break;
    case "id":
        templang = id;
        break;
    default:
        templang = en;
        break;
}
export const lang = templang;