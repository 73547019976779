
function TokenChecker(){
    if (localStorage.getItem('user_id') == null ){
        localStorage.removeItem('user_id')
        return false
    } else {
        return true
    }
}

export default TokenChecker;