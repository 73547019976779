import React, { Fragment } from 'react';
import { Table, Card, Col, Row, Pagination, Popover, Icon, Popconfirm, message, Divider, Button, Modal, Input} from 'antd';
import AddButton from '../../addButton';
import SearchBar from '../../SearchBar';
import { RouterConstant } from '../../../enum/routerConstant';
import * as destinationGroupService from '../../../acessApi/SystemAdmin/destinationGroupService'
import PageTitle from '../../pageTitle';
import { RoleChecker } from '../../../utility/RoleChecker';
import './index.css';
import { Link } from 'react-router-dom';

const { Search } = Input;
const { confirm } = Modal;

class DestinationGroupList extends React.Component {  
  constructor(props) {
    super(props);
    this.columndata = [
      {
        width: 700,
        title: 'Group Name',
        dataIndex: 'groupName',
        key: 'groupName',
        render: text => <p>{text}</p>,
      },
      {
        width: 100,
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Popover content={"Group detail"}>
              <Link to = { RouterConstant.SYSADMIN.GROUP.detail + '/' + record.key } key={record.key} >
                <Icon type="unordered-list" style={{margin : '5px', color : "#7a7a7a", fontSize:"14px"}} />
              </Link>
            </Popover>
            {
              (
                RoleChecker() == true ?
                <Fragment>
                  <Divider type="vertical" />
                  <Popover content={"Edit group"}>
                    <Link to = { RouterConstant.SYSADMIN.GROUP.edit + '/' + record.key } key={record.key} >
                      <Icon type="edit" theme="filled" style={{margin : '5px', color : "#7a7a7a", fontSize:"16px"}} />
                    </Link>
                  </Popover>
                </Fragment>
                : ""
              )
            }
          </span>
        ),
      },
    ];
    this.state = { 
      destinationGroupData: null, 
      currentPage: 1, 
      totalPages: null, 
      sourceList: null, 
      totalData: null, 
      itemPerPage: 10, 
      groupData : null,
      selectedRowKeys: [],
      size: ["10","25","50"],
      searchBy : "groupname",
      searching: false,
      searchKey: null,
      lastupdate : {},
      loading: false
    };
  }

  async getData(pageLimit, currentPage) {
    this.setState({ 
      destinationGroupData: await destinationGroupService.getDestinationGroupListDataFromApi(currentPage, pageLimit) 
    });
    this.mapData()
  }

  onPageChange = async page => {
    await this.setState({
      currentPage: page,
    });
    if (this.state.searching == true){
      this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, this.state.searchKey)
    } else {
      this.getData(this.state.itemPerPage, this.state.currentPage)
    }
  };

  handleDelete = async ()  =>  {
    message.destroy();
    message.loading("Deleting Data", 0);
    let dataToBeDeleted = []
    const selectedTemp = this.state.selectedRowKeys
    await selectedTemp.map( async id =>{
      dataToBeDeleted.push(
        {
          id : parseInt(id),
          record_meta_data: {
                  last_updated_at: this.state.lastupdate[`${id}`]
          }
        }
      )
    })
    const response = (await destinationGroupService.deleteMultiple(dataToBeDeleted));
    message.destroy();
    if (response.data.nexsoft_message.payload.data.error_code === "Oke") {
      message.success("Destination group has been deleted!");
      this.setState({isFetching: false, selectedRowKeys : null});
      this.componentDidMount()
    } else {
      message.error("Error encountered from server");
      this.setState({isFetching: false, selectedRowKeys : null});
      this.componentDidMount()
    }
  }

  onShowSizeChange = async (current, pageSize) => {
    await this.setState({
      itemPerPage : pageSize,
      currentPage : 1
    });
    if (this.state.searching == true){
      this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, this.state.searchKey)
    } else {
      this.getData(this.state.itemPerPage, this.state.currentPage)
    }
  }

  async mapData(){
    let result = []
    let lastupdated = {}
    let data = []
    data = await this.state.destinationGroupData.data.nexsoft_message.payload.data.additional_info
    if (data != null) {
      data.map(results => {
        result.push({
          key: `${results.Id}`,
          groupName: `${results.Group_name}`,
        });
        lastupdated[`${results.Id}`] = `${results.record_meta_data.last_updated_at}`
      });
      this.setState({
        groupData: result,
        lastupdate: lastupdated
      });
    }else{
      this.setState({
        groupData : result
      })  
    }
  }

  showDeleteAlert = () => {
    if (this.state.selectedRowKeys.length != 0) {
      confirm({
        title: 'Are you sure delete destination group?',
        content: 'If destination group deleted maybe it will cause error on all client that related to this destination group. ',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk : ()=>this.handleDelete()
      });
    }else{
      message.error("No Destination group selected");
    }
  }
  

  async componentDidMount(){
    await this.setState({
      totalData: await (await destinationGroupService.countTotalData()).data.nexsoft_message.payload.data.additional_info
    })
    await this.getData(this.state.itemPerPage, this.state.currentPage)
  }

  handleSearchSubmit = async value => {
    this.setState({
      searching : false
    })
    if (value === null || value === "") {
      this.componentDidMount()
    } else {
      await this.setState({
        searchKey : value,
        searching : true
      })
      this.searchClient()
    }
  }

  async searchClient(){
    this.setState({
      totalData: await (await destinationGroupService.countSearch(this.state.searchBy, this.state.searchKey)).data.nexsoft_message.payload.data.additional_info,
      currentPage : 1
    })
    this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, this.state.searchKey)
  }

  async getSearch(pageLimit, currentPage, searchBy, searchkey) {
    this.setState({ destinationGroupData: await destinationGroupService.getSearchData(pageLimit, currentPage, searchBy, searchkey) });
    this.mapData()
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };
  
  render(){
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      width : '10px'
    };
    const pagination = {
      position: 'top', 
      defaultCurrent: this.state.currentPage,
      total: this.state.totalData, 
      onChange: this.onPageChange,
      showSizeChanger: true, 
      onShowSizeChange: this.onShowSizeChange, 
      pageSizeOptions: ["10", "20", "30", "40"],
      current : this.state.currentPage,
    };
    return(
      <Fragment >
        <Row>
          <Card style={{ borderRadius: '10px', boxShadow: '5px 5px 20px #888888', minHeight: '800px', margin: '15px' }}>
            <Row style={{ margin: '5px' }}>
              <Col span={6}>
                <PageTitle TitleText="Destination Groups"/>
              </Col>
            </Row>
            <Row >
              <Col span={4}>
                <Search style={{margin: '5px'}}
                  placeholder={"Search by group name"}
                  allowClear
                  onSearch={value => this.handleSearchSubmit(value)}
                />
              </Col>
              <Col span={20} style={{ textAlign: 'right' }}>
                {
                  (
                    RoleChecker() == true ? 
                  <Fragment>
                    {/* <Popconfirm
                      title="Are you sure delete selected data type?"
                      onConfirm={() => this.handleDelete()}
                      okText="Yes"
                      cancelText="No"
                      > */}
                      <Button onClick={() => this.showDeleteAlert()} style={{margin : '5px', width : '135px'}}>
                        Delete
                      </Button>
                    {/* </Popconfirm> */}
                      <AddButton link={RouterConstant.SYSADMIN.GROUP.create} text="Add" />
                  </Fragment>
                  : ""
                  )
                }
              </Col>
            </Row>
            <Row>
                <Table
                  rowSelection={rowSelection} 
                  dataSource={this.state.groupData}
                  columns={this.columndata}
                  pagination={pagination}
                  onChange={this.handleTableChange}
                  scroll={{ y: true }}
                  size="small"
                />
            </Row>
          </Card>
        </Row>
      </Fragment>
    )   
  }
}

export default DestinationGroupList;
