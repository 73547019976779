import en from "./en"
import id from "./id"

// const templang = localStorage.getItem("language") === 'id' ? id : en;


export function getLang(chosenLang){
    let templang;
    switch (chosenLang) {
        case "en":
            templang = en;
            break;
        case "id":
            templang = id;
            break;
        default:
            templang = en;
            break;
    }
    return templang
}