const en = 
    {   
        creatingApi: "Sedang memperbaharui klien API server",
        apiClientCreated : "Klien API server sudah berhasil diperbaharui!",
        errorCreating : "Terjadi kesalahan server",
        passwordNotMatch : "Password tidak sama!",
        title : "Sunting klien API Server",
        save : "Simpan",
        username : "Username",
        usernameMessage : "Tolong masukkan username yang valid! Format username adalah a-z A-Z 0-9 _- minimal 3 karakter dan maksimal 16 karakter.",
        principal : "Principal",
        clientName : "Client Name",
        clientNameMessage : "Tolong masukkan Client Name!",
        clientCode : "API Client Code",
        clientCodeMessage : "Tolong masukkan Api Client Code!",
        email : "E-Mail",
        emailMessage : "Tolong masukkan E-mail!",
        emailError : "Tolong masukkan email yang valid!",
        connectionInfo : "Connection Info",
        connectionInfoError : "Tolong masukkan Connection Info!",
        address : "Alamat",
        addressMessage : "Tolong masukkan alamat!",
        phoneNumber : "Nomor Telepon",
        phoneNumberMessage : "Tolong masukkan nomor telepon!",
    }
export default en