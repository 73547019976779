import * as headerFormat from '../../common/createNexsoftMessageHeader'
import {apiUrl}  from "../urlConstant";
import axios from "axios";
import * as nexsoftMessage from '../../enum/nexsoftMessage';

export function downloadLog() {
    const url = apiUrl + '/monitoring/downloadlog';
    const payload = {
        "header":{
         }
    }
    return postData(url, headerFormat.createHeader("DOWNLOAD"), payload)
}

export async function postData(url, messageHeader, messagePayload) {
    const requestUrl = url;
    const config = {
        url : requestUrl,
        method : 'post',
        data : nexsoftMessage.createNexsoftMessage(messageHeader, messagePayload),
        responseType: 'arraybuffer',
        headers : {
            'Content-Type' : 'application/json'
        }
    }
    // if ((await axios(config)).data){
        
    // }
    return axios(config)
}