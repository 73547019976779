import axios from "axios";
import * as nexsoftMessage from '../enum/nexsoftMessage';
import _ from 'lodash'

export function getData(url) {
    const requestUrl = url;
    const config = {
        url : requestUrl,
        method : 'get',
        headers : {
            'Content-Type' : 'application/json'
        }
    }
    return axios(config);
}

export async function postData(url, messageHeader, messagePayload) {
    const requestUrl = url;
    const config = {
        url : requestUrl,
        method : 'post',
        data : nexsoftMessage.createNexsoftMessage(messageHeader, messagePayload),
        headers : {
            'Content-Type' : 'application/json'
        }
    }
    // if ((await axios(config)).data){
        
    // }
    return axios(config)
}