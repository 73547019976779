import * as baseApi from '../baseApi.js';
import * as headerFormat from '../../common/createNexsoftMessageHeader'
import {apiUrl}  from "../urlConstant";

export async function countSearch(search_by, key) {
    const url = apiUrl + '/monitoring/datatype/search/count'
    const payload = {
        "header":{
           "search_by": search_by,
           "key": key,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("SEARCH"), payload)
}

export async function getSearchData(limit, page, search_by, key) {
    const url = apiUrl + '/monitoring/datatype/search/list'
    const payload = {
        "header":{
           "page": page,
           "limit": limit,
           "search_by": search_by,
           "key": key,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("SEARCH"), payload)
}

export function countTotalData() {
    const url = apiUrl + '/monitoring/datatype/count';
    const payload = {}
    return baseApi.postData(url, headerFormat.createHeader("COUNT"), payload)
      
}


export function getDataTypeList(limit, page) {
    const url = apiUrl + '/monitoring/datatype/list'
    const payload = {
        "header":{
           "page":page,
           "limit":limit,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("LIST"), payload)
    
}

export async function createDataType(data) {
    const url = apiUrl + '/monitoring/datatype/create'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            "Data_type":data.dataType
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("CREATE"), payload)
}

export async function getDataTypeDetail(data) {
    const url = apiUrl + '/monitoring/datatype/view'
    const payload = {
        "header":{
           'id' : parseInt(data)
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("VIEW"), payload)
}

export async function deleteMultiple(clientData) {
    const url = apiUrl + '/monitoring/datatype/multipledelete'
    console.log(clientData)
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'array_data' : clientData
    }
    console.log('payload',payload)
    return baseApi.postData(url, headerFormat.createHeader("DELETE"), payload)
}


export async function deleteDataType(data) {
    const url = apiUrl + '/monitoring/datatype/delete'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            'id' : data.Id,
            'principal_id' : localStorage.getItem('principal_id'),
            'data_type' : data.Data_type,
            'record_meta_data' : {
                "last_updated_at": data.Record_meta_data.Last_updated_at
            }
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("DELETE"), payload)
}

export async function updateDataType(data) {
    const url = apiUrl + '/monitoring/datatype/update'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            'id' : data.id,
            'principal_id' : localStorage.getItem('principal_id'),
            'data_type' : data.dataType,
            'record_meta_data' : {
                "last_updated_at": data.lastUpdated
            }
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("UPDATE"), payload)
}