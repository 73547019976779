import * as baseApi from '../baseApi.js';
import * as headerFormat from '../../common/createNexsoftMessageHeader'
import {apiUrl}  from "../urlConstant";

export function countDeletedHousekeepingHistory(id) {
    const url = apiUrl + '/monitoring/deletehousekeeping/count'
    const payload = {
        "header":{
           "id": parseInt(id)
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("COUNT"), payload)
      
}

export function getDeletedHouskeepingHistory(page, limit) {
    const url = apiUrl + '/monitoring/deletehousekeeping/list'
    const payload = {
        "header":{
           "page":page,
           "limit":limit,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("LIST"), payload)
    
}

export function getHistoryHousekeepingList(page, limit) {
    const url = apiUrl + '/monitoring/historyhousekeeping/list'
    const payload = {
        "header":{
           "page":page,
           "limit":limit,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("LIST"), payload)
    
}


export function countHistoryHousekeeping() {
    const url = apiUrl + '/monitoring/historyhousekeeping/count';
    const payload = {}
    return baseApi.postData(url, headerFormat.createHeader("COUNT"), payload)
}
