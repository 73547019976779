import React, { Fragment } from 'react';
import 'antd/dist/antd.css';
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col, 
  message
} from 'antd';
import { RouterConstant } from '../../../enum/routerConstant';
import PageTitle from '../../pageTitle';
import backlogo from '../../../assets/icon/back-icon.svg'
import { Link } from 'react-router-dom';
import AddButton from '../../addButton';
import * as dataTypeService from '../../../acessApi/SystemAdmin/dataTypeService';
import {AccessChecker} from '../../../utility/AccessChecker'

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isFetching: false,
        confirmDirty: false,
        autoCompleteResult: [],
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.postData(values)
      }
    });
  };

  async postData(data) {
    message.destroy();
    message.loading("Submitting your data, sit tight", 0);
    this.setState({isFetching: true});
    const response = (await dataTypeService.createDataType(data));
    message.destroy();
    if (response.data.nexsoft_message.payload.data.error_code === "Oke") {
      message.success("New Data Type has been created");
      this.props.form.resetFields();
      this.setState({isFetching: false});
    } else {
      message.error("Error encountered from server");
      this.setState({isFetching: false});
    }
  }

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  goback(){
    window.history.back();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    AccessChecker()
    const formItemLayout = {
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    return (
      <Card style={{borderRadius : '10px', boxShadow :'5px 5px 20px #888888', minHeight: '600px', margin: '15px'}}>
        <Row>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Row style={{margin : '5px'}}>
              <a onClick={() => { this.goback()}} >
                <Col span={1} style={{lineHeight : '30px', textAlign: 'center'}}>
                  <img src={backlogo} alt="logoutLogo" style={{width: '18px'}}></img>
                </Col>
              </a>
              <Col span={10} style={{lineHeight : '30px'}}>
                <PageTitle TitleText="Add Data Type"/>
              </Col>
              <Col span={13} style={{textAlign : 'right'}}>
                {/* <Button onClick={() => { this.goback()} }
                disabled={this.state.isFetching} style={{ width: '135px', margin:"5px"}}>
                    Cancel
                </Button> */}
                <Button htmlType="submit" disabled={this.state.isFetching} style={{backgroundColor : '#5493FF', color: 'white', width: '135px', margin:"5px"}}>
                    Save
                </Button>
              </Col>
            </Row>
            <Row style={{fontSize : '16px', fontWeight: 'bold', margin :'10px'}}>
              <Form.Item label="Data Type Name" >
              {getFieldDecorator('dataType', {
                  rules: [
                  {
                      required: true,
                      message: 'Please input data type name! 3 to 16 alphanumeric character.',
                      pattern: new RegExp("^[a-zA-Z0-9 _-]{3,16}$"),
                  },
                  ],
              })(<Input />)}
              </Form.Item>
            </Row>
          </Form>
        </Row>
      </Card>
    );
  }
}

const AddNewSource = Form.create({ name: 'add' })(RegistrationForm);

export default AddNewSource;
          