import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { RouterConstant } from '../../enum/routerConstant';
import { ApiServerClientLogo, DestinationGroupLogo, UploadMonitoringLogo, DataTypeLogo, LogoutLogo, ConfigurationLogo, HousekeepingLogo, DownloadLog } from '../navigationItem';
import './index.css';
import menulogo from '../../assets/sidebar/menu.svg'
import { RoleChecker } from '../../utility/RoleChecker';
import * as downloadLogService from '../../acessApi/SystemAdmin/logService'
import { message } from 'antd'


class SideBar extends React.Component {
    state = {
        collapsed: true,
      };
    
    toggleCollapsed = () => {
        this.setState({
          collapsed: !this.state.collapsed,
        });
        if (this.state.collapsed === true) {
            document.getElementById('navmenu').style.width = '400px'
        } else {
            document.getElementById('navmenu').style.width = '60px'
        }
    };

    async downloadLog(){
        if (RoleChecker() === true) {
          try {
            message.destroy();
            message.loading("Downloading Log", 0);
            const text = await downloadLogService.downloadLog()
            console.log(text)
            message.destroy();
            if (text.status == 200) {
                var data = new Blob([text.data], {type: 'application/zip'});
                var url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'backupLog.zip');
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                message.success("Success Download Log");
            }else{
                message.error("Failed Download Log");
            }
        } catch {
            message.error("Error encountered from server.");
          }
        } else {
          message.error("You dont have the access to download");
        }
    }

    handleLogout(){
        localStorage.clear();
        window.location.replace("/");
    }
      
    render(){
        return (
            <div className="nav-container">
                <nav className="main-menu" id="navmenu">
                    <ul>
                        <div className="hamburgContainer" onClick={this.toggleCollapsed} >
                            <img src={menulogo} alt="logoutLogo" style={{width: '100%'}}></img>
                        </div>
                    </ul>
                    <ul>
                        <Link to = { RouterConstant.SYSADMIN.SOURCE.list }>
                            <ApiServerClientLogo/>
                        </Link>
                        <Link to = { RouterConstant.SYSADMIN.GROUP.list }>
                            <DestinationGroupLogo/>
                        </Link>
                        <Link to = { RouterConstant.SYSADMIN.JOB.list }>
                            <UploadMonitoringLogo/>
                        </Link>
                        <Link to = { RouterConstant.SYSADMIN.TYPE.list }>
                            <DataTypeLogo/>
                        </Link>
                        {
                            (
                                RoleChecker() === true ? 
                                <Fragment>
                                    <Link to = { RouterConstant.SYSADMIN.HOUSEKEEPING.history }>
                                        <HousekeepingLogo/>
                                    </Link>
                                    <Link to = { RouterConstant.SYSADMIN.CONFIGURATION.list }>
                                        <ConfigurationLogo/>
                                    </Link>
                                    <a onClick={()=> this.downloadLog()} >
                                        <DownloadLog/>
                                    </a>
                                </Fragment> 
                                : 
                                ""
                            )
                        }
                    </ul>
                    <ul className="logout">
                        <a onClick={()=> this.handleLogout()}>
                            <LogoutLogo />
                        </a>
                    </ul>
                </nav>
            </div>
        );
    }
}
  
  export default SideBar;