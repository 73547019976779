import * as baseApi from '../baseApi.js';
import * as headerFormat from '../../common/createNexsoftMessageHeader'
import {apiUrl}  from "../urlConstant";

export function countTotalData() {
    const url = apiUrl + '/monitoring/serversetting/count';
    const payload = {}
    return baseApi.postData(url, headerFormat.createHeader("COUNT"), payload)
      
}


export function getConfigurationList(page, limit) {
    const url = apiUrl + '/monitoring/serversetting/list'
    const payload = {
        "header":{
           "page":page,
           "limit":limit,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("LIST"), payload)
    
}

export async function createConfiguration(data) {
    const url = apiUrl + '/monitoring/datatype/create'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            "Data_type":data.dataType
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("CREATE"), payload)
}

export async function getConfigurationDetail(data) {
    const url = apiUrl + '/monitoring/serversetting/view'
    const payload = {
        "header":{
           'id' : parseInt(data)
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("VIEW"), payload)
}


export async function updateConfiguration(data) {
    const url = apiUrl + '/monitoring/serversetting/update'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            'id' : data.id,
            'setting_value' : data.keyValue,
            "setting_value_type" : data.dataType,
            'record_meta_data' : {
                "last_updated_at": data.lastUpdated
            }
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("UPDATE"), payload)
}