import React, { Fragment } from 'react';
import { Card, Col, Row, Pagination, Popover, Icon, message, Popconfirm, Table, Input, Divider, Button, Dropdown, Menu } from 'antd';
import { RouterConstant } from '../../../enum/routerConstant';
import backlogo from '../../../assets/icon/back-icon.svg'
import AddButton from '../../addButton';
import PageTitle from '../../pageTitle';
import * as destinationGroupService from '../../../acessApi/SystemAdmin/destinationGroupService'
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import GetSearchText from "../../../common/getSearchByText"
import './index.css';
import { RoleChecker } from '../../../utility/RoleChecker';
const { Search } = Input;

class DestinationGroupDetail extends React.Component {

  constructor(props) {
    super(props);
    this.searchBy = (
      <Menu>
        <Menu.Item>
          <div onClick={
            () => {
              this.setState({
                searchBy: 'username'
              })
            }
          }>
            username
            </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={
            () => {
              this.setState({
                searchBy: 'email'
              })
            }
          }>
            e-mail
            </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={
            () => {
              this.setState({
                searchBy: 'client_name'
              })
            }
          }>
            client name
            </div>
        </Menu.Item>
      </Menu>
    );
    this.columndata = [
      {
        width: 100,
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        render: text => <p>{text}</p>,
      },
      {
        width: 100,
        title: 'Client Name',
        dataIndex: 'clientName',
        key: 'clientName',
        render: text => <p>{text}</p>,
      },
      {
        width: 200,
        title: 'E-Mail',
        dataIndex: 'email',
        key: 'email',
        render: text => <p>{text}</p>,
      },
      {
        width: 200,
        title: 'Connection Info',
        dataIndex: 'connInfo',
        key: 'connInfo',
        render: text => <p>{text}</p>,
      },
      {
        width: 75,
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <div >
            <Popover content={"Api client detail"}>
              <Link to={RouterConstant.SYSADMIN.SOURCE.detail + '/' + record.key} key={record.key}>
                <Icon type="unordered-list" style={{ margin: '5px', color: "#7a7a7a", fontSize: "14px" }} />
              </Link>
            </Popover>
          </div>
        ),
      },
    ];
    this.state = {
      signedClient: null,
      currentPage: 1,
      totalPages: null,
      sourceList: null,
      totalData: null,
      itemPerPage: 10,
      groupData: null,
      selectedRowKeys: [],
      loading: false,
      searching: false,
      searchBy: "username",
      searchKey: null,
      groupId : null
    };
  }

  async searchClient() {
    await this.setState({
      totalData: await (await destinationGroupService.countSearchSigned(this.state.searchBy, this.state.searchKey, this.state.groupId)).data.nexsoft_message.payload.data.additional_info,
      currentPage: 1
    })
    this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, this.state.searchKey, this.state.groupId)
  }

  async getSearch(pageLimit, currentPage, searchBy, searchkey, groupId) {
    this.setState({ signedClient: await destinationGroupService.getSearchSigned(pageLimit, currentPage, searchBy, searchkey, groupId) });
    this.mapData()
  }

  handleSearchSubmit = async value => {
    if (value === null || value === "") {
      this.setState({
        searching : false
      })
      this.componentDidMount()
    } else {
      await this.setState({
        searchKey : value,
        searching: true,
        currentPage: 1
      })
      this.searchClient()
    }
  }

  async getData(pageLimit, currentPage) {
    this.setState({
      signedClient: await destinationGroupService.getSignedClient(this.props.match.params.id, currentPage, pageLimit)
    });
    this.mapData()
  }

  onPageChange = async page => {
    await this.setState({
      currentPage: page,
    });
    if (this.state.searching == true) {
      this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, this.state.searchKey, this.state.groupId)
    } else {
      this.getData(this.state.itemPerPage, this.state.currentPage)
    }
  };

  async unsignClient() {
    if (this.state.selectedRowKeys.length != 0) {
      message.destroy();
      message.loading("Unsigning Group", 0);
      let dataToBeDeleted = []
      const groupData = {
        id: this.state.signedClient.data.nexsoft_message.payload.data.additional_info.id,
        groupName: this.state.signedClient.data.nexsoft_message.payload.data.additional_info.group_name,
        lastUpdated: this.state.signedClient.data.nexsoft_message.payload.data.additional_info.record_meta_data.Last_updated_at,
      }
      const selectedTemp = this.state.selectedRowKeys
      await selectedTemp.map(async id => {
        dataToBeDeleted.push(
          {
            id: parseInt(id),
          }
        )
      })
      const response = (await destinationGroupService.unsignClient(dataToBeDeleted, groupData));
      message.destroy();
      if (response.data.nexsoft_message.payload.data.error_code === "Oke") {
        message.success("Destination group are unsigned!");
        this.setState({ isFetching: false });
        this.componentDidMount()
      } else {
        message.error("Error encountered from server");
        this.setState({ isFetching: false });
        this.componentDidMount()
      }
    } else {
      message.error("No API client selected");
    }
  }

  onShowSizeChange = async (current, pageSize) => {
    await this.setState({
      itemPerPage: pageSize,
      currentPage: 1
    });
    if (this.state.searching == true) {
      this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, this.state.searchKey, this.state.groupId)
    } else {
      this.getData(this.state.itemPerPage, this.state.currentPage)
    }
  }

  async mapData() {
    let result = []
    const data = await this.state.signedClient.data.nexsoft_message.payload.data.additional_info.destinations
    if (data != null) {
      data.map((results) =>
        result.push({
          key: `${results.id}`,
          username: `${results.username}`,
          clientName: `${results.client_name}`,
          email: `${results.email}`,
          connInfo: `${results.connection_info}`,
        })
      )
      this.setState({
        pageTitle: this.state.signedClient.data.nexsoft_message.payload.data.additional_info.group_name,
        groupData: result
      })
    } else {
      this.setState({
        pageTitle: this.state.signedClient.data.nexsoft_message.payload.data.additional_info.group_name,
        groupData: result
      })
    }
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  goback() {
    window.history.back();
  }

  async componentDidMount() {
    await this.setState({
      totalData: await (await destinationGroupService.countSignedClient(this.props.match.params.id)).data.nexsoft_message.payload.data.additional_info,
      selectedRowKeys: [],
      groupId : parseInt(this.props.match.params.id)
    })
    await this.getData(this.state.itemPerPage, this.state.currentPage)
  }

  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      width: '10px'
    };
    const pagination = {
      position: 'top', 
      defaultCurrent: this.state.currentPage,
      total: this.state.totalData, 
      onChange: this.onPageChange,
      showSizeChanger: true, 
      onShowSizeChange: this.onShowSizeChange, 
      pageSizeOptions: ["10", "20", "30", "40"],
      current : this.state.currentPage,
    };
    return (
      <Fragment >
        <Row>
          <Card style={{ borderRadius: '10px', boxShadow: '5px 5px 20px #888888', minHeight: '800px', margin: '15px' }}>
            <Row style={{ margin: '5px' }}>
              <a onClick={() => { this.goback() }} >
                <Col span={1} style={{ lineHeight: '30px', textAlign: 'center' }}>
                  <img src={backlogo} alt="backlogo" style={{ width: '18px' }}></img>
                </Col>
              </a>
              <Col span={4}>
                <PageTitle TitleText={this.state.pageTitle} />
              </Col>
            </Row>
            <Row style={{ margin: '5px' }}>
              <Col span={4}>
                <Search style={{ margin: '5px' }}
                  placeholder="Search"
                  allowClear
                  onSearch={value => this.handleSearchSubmit(value)}
                />
              </Col>
              <Col span={5}>
                <Dropdown overlay={this.searchBy}>
                  <Button style={{ margin: '5px', marginLeft: '15px' }}>
                    <GetSearchText searchstate={this.state.searchBy} /><Icon type="down" />
                  </Button>
                </Dropdown>
              </Col>
              <Col span={15} style={{ textAlign: 'right' }}>
                {
                  (
                    RoleChecker() == true ?
                      <Fragment>
                        <Popconfirm
                          title="Are you sure unsign these client?"
                          onConfirm={() => this.unsignClient()}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button style={{ margin: '5px', width: '135px' }}>
                            Unsign
                          </Button>
                        </Popconfirm>
                        <AddButton link={RouterConstant.SYSADMIN.GROUP.add + "/" + this.props.match.params.id} text="Add" />
                      </Fragment>
                      : ""
                  )
                }
              </Col>
            </Row>
            <Row>
              <div>
                <Table
                  rowSelection={rowSelection}
                  dataSource={this.state.groupData}
                  columns={this.columndata}
                  pagination={pagination}
                  onChange={this.handleTableChange}
                  size="small"
                />
              </div>
            </Row>
          </Card>
        </Row>
      </Fragment>
    )
  }
}



export default withRouter(DestinationGroupDetail);
