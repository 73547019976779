import React from 'react';
import { Card, Col } from 'antd';

class Home extends React.Component {
    render(){
        return(
            <Col span={ 24 }>
                <Card title="Home" style={{borderRadius : '15px'}}>
                    <p>Home Content</p>
                </Card>
            </Col>
            // <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
            //     <h1>Univenus API</h1>
            // </div>
        )   
    }
}
  
export default Home;