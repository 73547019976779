const en = 
    {   
        creatingApi: "Creating new API server Client",
        apiClientCreated : "API Server Client has been created!",
        errorCreating : "Error encountered from server",
        passwordNotMatch : "Password Not Match!",
        title : "Add API Server Client",
        save : "Save",
        username : "Username",
        usernameMessage : "Please input valid username! Username format is a-z A-Z 0-9 _- minimum of 3 character and maximum is 16 character.",
        password : "Password",
        passwordMessage : "Password format a-z A-Z 0-9 _- minimum of 6 character and maximum is 16 character",
        confirmPassword : "Confirm Password",
        confirmPasswordMessage : "Those password are not match!",
        clientName : "Client Name",
        clientNameMessage : "Please input Client Name!",
        clientCode : "API Client Code",
        clientCodeMessage : "Please input Api Client Code!",
        email : "E-Mail",
        emailMessage : "Please input your E-mail!",
        emailError : "The input is not valid E-mail!",
        connectionInfo : "Connection Info",
        connectionInfoError : "Please input Connection Info!",
        address : "Address",
        addressMessage : "Please input address!",
        phoneNumber : "Phone Number",
        phoneNumberMessage : "Please input phone number!",
    }
export default en