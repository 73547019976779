import React, { Fragment } from 'react';
import 'antd/dist/antd.css';
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col, 
  message,
  Modal
} from 'antd';
import { RouterConstant } from '../../../enum/routerConstant';
import PageTitle from '../../pageTitle';
import backlogo from '../../../assets/icon/back-icon.svg'
import { Link } from 'react-router-dom';
import AddButton from '../../addButton';
import * as dataTypeService from '../../../acessApi/SystemAdmin/dataTypeService';
import {AccessChecker} from '../../../utility/AccessChecker'

const { confirm } = Modal;

class EditSourceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        id : null,
        pageTitle : null,
        dataType : null,
        lastUpdated : null,
        isFetching: false,
        confirmDirty: false,
        autoCompleteResult: [],
    }
  }

  
    componentDidMount(){
        this.getData()
    }

    async getData(){
        const id = this.props.match.params.id;
        let dataTypeData = []
        dataTypeData = await dataTypeService.getDataTypeDetail(id)
        const data = await dataTypeData.data.nexsoft_message.payload.data.additional_info
        if (data.Data_type != null && data.Data_type != "") {
            this.setState({
                pageTitle : <PageTitle TitleText={data.Data_type} titleColor="black"/>,
                id : data.Id,
                dataType : data.Data_type,
                lastUpdated : data.record_meta_data.last_updated_at
            })
        }else{
            this.setState({
                pageTitle : <PageTitle TitleText="No Data" titleColor="black"/>
            })
        }
    }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.postData(values)
      }
    });
  };

  async postData(data) {
    message.destroy();
    message.loading("Submitting your data, sit tight", 0);
    this.setState({isFetching: true});
    const response = (await dataTypeService.updateDataType(data));
    message.destroy();
    if (response.data.nexsoft_message.payload.data.error_code === "Oke") {
      message.success("Api client has been updated");
    } else {
      message.error("Error encountered from server");
    }
    this.getData()
    this.setState({isFetching: false});
}

  handleWebsiteChange = value => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
    }
    this.setState({ autoCompleteResult });
  };

  goback(){
    window.history.back();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
    if (!err) {
      confirm({
        title: 'Are you sure to update data type?',
        content: 'If data type is updated maybe it will cause error on all client that related to this data type.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk : ()=>this.postData(values)
      });
    }
    });
  };

  render() {

    const { getFieldDecorator } = this.props.form;
    AccessChecker()
    const formItemLayout = {
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }
    return (
      <Card style={{borderRadius : '10px', boxShadow :'5px 5px 20px #888888', minHeight: '600px'}}>
        <Row>
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Row style={{margin : '5px'}}>
                    <a onClick={() => { this.goback()}} >
                    <Col span={1} style={{lineHeight : '30px', textAlign: 'center'}}>
                        <img src={backlogo} alt="logoutLogo" style={{width: '18px'}}></img>
                    </Col>
                    </a>
                    <Col span={10} style={{lineHeight : '30px'}}>
                        {this.state.pageTitle}
                    </Col>
                    <Col span={13} style={{textAlign : 'right'}}>
                    <Button onClick={() => { this.goback()} }
                    disabled={this.state.isFetching} style={{ width: '135px', margin:"5px"}}>
                        Cancel
                    </Button>
                    <Button htmlType="submit" disabled={this.state.isFetching} style={{backgroundColor : '#5493FF', color: 'white', width: '135px', margin:"5px"}}>
                        Save
                    </Button>
                    </Col>
                </Row>
                <Row style={{fontSize : '16px', fontWeight: 'bold', margin :'10px'}}>
                <Form.Item label="Data Type Name" >
                {getFieldDecorator('dataType', {
                    initialValue: this.state.dataType,
                    rules: [
                    {
                        required: true,
                        message: '3 to 16 alphanumeric character.',
                        pattern: new RegExp("^[a-zA-Z0-9 _-]{3,16}$"),
                    },
                    ],
                })(<Input placeholder={(this.state.dataType)} />)}
                </Form.Item>
                    {getFieldDecorator('lastUpdated', {initialValue: this.state.lastUpdated})}
                    {getFieldDecorator('id', {initialValue: this.state.id})}
                </Row>
            </Form>
        </Row>
      </Card>
    );
  }
}

const EditApiClient = Form.create({ name: 'add' })(EditSourceForm);

export default EditApiClient;
          