import React, { Fragment } from 'react';
import './App.css';
import { Route, Redirect } from "react-router-dom";

import Home from './components/home';

import nexsoftLogo from './assets/logo/nexsoft-logo.svg';
import { Row, Col, Icon, Modal, Typography, message } from 'antd';
import axioshome from "axios";
import {RouterConstant} from './enum/routerConstant';


import SourceDetail from './components/apiClient/sourceDetail';
import SourceList from './components/apiClient/sourceList';
import AddNewSource from './components/apiClient/createSource';
import EditApiClient from './components/apiClient/editSource';
import ChangePassword from './components/apiClient/changePassword';


import EditDataType from './components/dataType/editDataType';
import DataTypeList from './components/dataType/dataTypeList';
import AddNewDataType from './components/dataType/createDataType';

import AddNewGroup from './components/destinationGroup/createDestinationGroup';
import DestinationGroupDetail from './components/destinationGroup/destinationGroupDetail';
import addClientToDestinationGroup from './components/destinationGroup/addDestinationGroup';
import DestinationGroupList from './components/destinationGroup/destinationGroupList';
import EditDestinationGroup from './components/destinationGroup/editDestinationGroup';


import MonitoringDetail from './components/jobProcess/jobProcessDetail'
import MonitoringList from './components/jobProcess/jobProcessList'
import ConfigurationList from './components/configurationPage/configurationList';
import ConfigurationEdit from './components/configurationPage/configurationEdit';
import HistoryHousekeeping from './components/housekeeping/historyHousekeeping';
import DeletedHistoryHousekeeping from './components/housekeeping/deletedHistoryHousekeeping';



const { Title, Text } = Typography;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      destinationGroupData: null, 
      currentPage: 1,
      infoVisible : false,  
    };
  }
  
  countDown() {
    Modal.error({
      title: 'Authentication Failed',
      content: 'You need to login',
      onOk : () => this.redirectToLogin()
    });
  }

  showInfo = () => {
    this.setState({
      infoVisible: true,
    });
  };


  cancelModal = () => {
    this.setState({ infoVisible: false });
  };

  
  redirectToLogin() {
    localStorage.clear();
    window.location.replace("/");
  }

  render(){
    axioshome.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.response) {
            if (error.response.status === 401) {
              this.countDown("Authentication Failed");
            } else if (error.response.status === 404) { 
              message.destroy()
              message.error("Api Not Found")
            } else if (error.response.status === 500) { 
              message.destroy()
              message.error("Error Encountered From Server")
            } else if (error.response.status === 400) { 
              message.destroy()
              message.error(error.response.data.nexsoft_message.payload.data.error_message)
            } 
        } else if (error.request) {
              // this.countDown("Server is Offline");
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        // this.setState({snackbarOpen: true});
        return Promise.reject(error);
    })
    return(
    <Fragment>
      <Row style={{marginBottom : '10px', lineHeight: '40px', marginRight : '20px', marginLeft: '20px'}}>
        <Col span={2}>
          <img src={nexsoftLogo} alt="nexsoftLogo" style={{height : '70px'}}></img>
        </Col>
        <Col span ={4} offset={18} style={{textAlign : 'right'}}>
          <Text >
            Welcome, <strong>{localStorage.getItem('user_name')} </strong>
            <a onClick onClick={()=> this.showInfo()} style={{color:"rgba(0, 0, 0, 0.65)"}}>
              <Icon type="info-circle" />
            </a>
          </Text>
        </Col>
      </Row>
      <Modal
          visible={this.state.infoVisible}
          title="Information"
          onOk={()=>this.cancelModal()}
          onCancel={()=>this.cancelModal()}
          footer={[
          ]}
        >
          <p>Version:1.0.0-RELEASE</p>
          <p>Contact us at :</p>
          <p>PT. PARAMADAKSA TEKNOLOGI NUSANTARA</p>
          <p>(021) 22220138</p>
          <p>cs@nexsoft.co.id</p>
        </Modal>
      <Fragment >
        
        {/* Home Routing */}
        <Route path='/' component={Home} exact/>
        <Route path='/home' component={Home} exact/>

        {/* source routing */}
        <Route path={RouterConstant.SYSADMIN.SOURCE.list} component={SourceList} exact/>
        <Route path={RouterConstant.SYSADMIN.SOURCE.detail + '/:id'} component={SourceDetail} exact/>
        <Route path={RouterConstant.SYSADMIN.SOURCE.create} component={AddNewSource} exact/>
        <Route path={RouterConstant.SYSADMIN.SOURCE.edit + '/:id'} component={EditApiClient} exact/>
        <Route path={RouterConstant.SYSADMIN.SOURCE.changePassword + '/:id'} component={ChangePassword} exact/>
        {/* source routing */}

        
        {/* Data Type Routing */}
        <Route path={RouterConstant.SYSADMIN.TYPE.list} component={DataTypeList} exact/>
        {/* <Route path={RouterConstant.SYSADMIN.TYPE.detail + '/:id'} component={DataTypeDetail} exact/> */}
        <Route path={RouterConstant.SYSADMIN.TYPE.create} component={AddNewDataType} exact/>
        <Route path={RouterConstant.SYSADMIN.TYPE.edit + '/:id'} component={EditDataType} exact/>
        {/* Data Type Routing */}

        {/* destination group routing */}
        <Route path={RouterConstant.SYSADMIN.GROUP.list} component={DestinationGroupList} exact/>
        <Route path={RouterConstant.SYSADMIN.GROUP.detail + '/:id'} component={DestinationGroupDetail} exact/>
        <Route path={RouterConstant.SYSADMIN.GROUP.create} component={AddNewGroup} exact/>
        <Route path={RouterConstant.SYSADMIN.GROUP.add  + '/:id'} component={addClientToDestinationGroup} exact/>
        <Route path={RouterConstant.SYSADMIN.GROUP.edit  + '/:id'} component={EditDestinationGroup} exact/>
        {/* destination group routing */}

        {/* data monitoring */}
        <Route path={RouterConstant.SYSADMIN.JOB.list} component={MonitoringList} exact/>
        <Route path={RouterConstant.SYSADMIN.JOB.detail + '/:id'} component={MonitoringDetail} exact/>
        {/* data monitoring */}


        {/* housekeeping */}
        <Route path={RouterConstant.SYSADMIN.HOUSEKEEPING.history} component={HistoryHousekeeping} exact/>
        <Route path={RouterConstant.SYSADMIN.HOUSEKEEPING.deletedHistory} component={DeletedHistoryHousekeeping} exact/>
        {/* housekeeping */}


        {/* configuration */}
        <Route path={RouterConstant.SYSADMIN.CONFIGURATION.list} component={ConfigurationList} exact/>
        <Route path={RouterConstant.SYSADMIN.CONFIGURATION.edit + '/:id'} component={ConfigurationEdit} exact/>
        {/* configuration */}

      </Fragment>
    </Fragment>
    )
  }
}


export default App;
