import * as baseApi from '../baseApi.js';
import * as headerFormat from '../../common/createNexsoftMessageHeader'
import {apiUrl}  from "../urlConstant";

export async function countSearch(search_by, key) {
    const url = apiUrl + '/monitoring/destinationgroup/search/count'
    const payload = {
        "header":{
           "search_by": search_by,
           "key": key,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("SEARCH"), payload)
}

export async function getSearchData(limit, page, search_by, key) {
    const url = apiUrl + '/monitoring/destinationgroup/search/list'
    const payload = {
        "header":{
           "page": page,
           "limit": limit,
           "search_by": search_by,
           "key": key,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("SEARCH"), payload)
}

export async function updateGroupData(data) {
    const url = apiUrl + '/monitoring/destinationgroup/update'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            'id' : parseInt(data.id),
            'group_name' : data.groupName,
            'principal_id' : data.principal,
            'record_meta_data' : {
                "last_updated_at": data.lastUpdated
            }
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("UPDATE"), payload)
}

export async function deleteMultiple(clientData) {
    const url = apiUrl + '/monitoring/destinationgroup/multipledelete'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'array_data' : clientData
    }
    return baseApi.postData(url, headerFormat.createHeader("DELETE"), payload)
}

export function getGroupInfo(id) {
    const url = apiUrl + '/monitoring/destinationgroup/view'
    const payload = {
        "header":{
            "id": parseInt(id),
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("VIEW"), payload)
      
}

export async function unsignClient(client, groupData) {
    const url = apiUrl + '/monitoring/destinationgroup/unsignclient'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            'id' : groupData.id,
            'group_name' : groupData.groupName,
            'principal_id' : localStorage.getItem('principal_id'),
            "destinations": client,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("UNSIGN"), payload)
}

export async function countSearchSigned(search_by, key, id) {
    const url = apiUrl + '/monitoring/destinationgroup/signedclient/searchcount'
    const payload = {
        "header":{
           "search_by": search_by,
           "id": id,
           "key": key,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("COUNT"), payload)
}

export async function getSearchSigned(limit, page, search_by, key, id) {
    const url = apiUrl + '/monitoring/destinationgroup/signedclient/searchlist'
    const payload = {
        "header":{
           "page": page,
           "limit": limit,
           "search_by": search_by,
           "id": id,
           "key": key,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("SEARCH"), payload)
}

export async function countSearchUnsigned(search_by, key, id) {
    const url = apiUrl + '/monitoring/destinationgroup/unsignedclient/searchcount'
    const payload = {
        "header":{
           "search_by": search_by,
           "key": key,
           "id": id,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("COUNT"), payload)
}

export async function getSearchUnsigned(limit, page, search_by, key, id) {
    const url = apiUrl + '/monitoring/destinationgroup/unsignedclient/searchlist'
    const payload = {
        "header":{
           "page": page,
           "limit": limit,
           "search_by": search_by,
           "key": key,
           "id": id,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("SEARCH"), payload)
}

export async function addClientToGroup(client, groupData) {
    const url = apiUrl + '/monitoring/destinationgroup/signclient'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            'id' : groupData.id,
            'group_name' : groupData.groupName,
            "destinations": client,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("SIGN"), payload)
}

export async function updateDestinationGroup(data) {
    const url = apiUrl + '/monitoring/destinationgroup/update'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            'id' : data.id,
            'username' : data.username,
            'password' : " ",
            'principal_id' : data.principal,
            'client_name' : data.clientName,
            'code' : data.serverCode,
            'email' : data.email,
            'connection_info' : data.connInfo,
            'record_meta_data' : {
                "last_updated_at": data.lastUpdated
            }
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("UPDATE"), payload)
}

export async function deleteDestinationGroup(data) {
    const url = apiUrl + '/monitoring/destinationgroup/delete'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            'id' : data.Id,
            'group_name' : data.Group_name,
            'principal_id' : localStorage.getItem('principal_id'),
            'record_meta_data' : {
                "last_updated_at": data.Record_meta_data.Last_updated_at
            }
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("DELETE"), payload)
}

export async function createDestinationGroup(data) {
    const url = apiUrl + '/monitoring/destinationgroup/create'
    const payload = {
        "header":{
           'id' : parseInt(localStorage.getItem('user_id'))
        },
        'data' : {
            'id' : null,
            'group_name' : data.groupName,
            'principal_id' : localStorage.getItem('principal_id'),
            'record_meta_data' : {
                "last_updated_at": ""
            }
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("CREATE"), payload)
}

export function getUnsignedClient(id, page, limit) {
    const url = apiUrl + '/monitoring/destinationgroup/unsignedclient/list'
    const payload = {
        "header":{
            "id": parseInt(id),
            "page"  : page,
            "limit" : limit    
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("LIST"), payload)
      
}

export function getSignedClient(id, page, limit) {
    const url = apiUrl + '/monitoring/destinationgroup/signedclient/list'
    const payload = {
        "header":{
            "id": parseInt(id),
            "page"  : page,
            "limit" : limit    
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("LIST"), payload)
      
}

export function getDestinationGroupListDataFromApi(page, limit) {
    const url = apiUrl + '/monitoring/destinationgroup/list'
    const payload = {
        "header":{
           "page":page,
           "limit":limit,
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("LIST"), payload)
    
}

export function countSignedClient(id) {
    const url = apiUrl + '/monitoring/destinationgroup/signedclient/count';
    const payload = {
        "header":{
           "id":parseInt(id)
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("COUNT"), payload)
      
}

export function countUnsignedClient(id) {
    const url = apiUrl + '/monitoring/destinationgroup/unsignedclient/count';
    const payload = {
        "header":{
           "id":parseInt(id),
        }
    }
    return baseApi.postData(url, headerFormat.createHeader("COUNT"), payload)
      
}

export function countTotalData() {
    const url = apiUrl + '/monitoring/destinationgroup/count';
    const payload = {}
    return baseApi.postData(url, headerFormat.createHeader("COUNT"), payload)
      
}
