import React, { Fragment } from 'react';
import { Table, Col, Row, Card, Layout, Popconfirm, message, Icon, Popover, Button, Input, Dropdown, Menu, Typography  } from 'antd';
import AddButton from '../../addButton';
import { RouterConstant } from '../../../enum/routerConstant';
import * as langService from '../../../enum/language/apiClient/list';
import { Link } from 'react-router-dom';
import PageTitle from '../../pageTitle';
import GetSearchText from "../../../common/getSearchByText"
import './index.css'
import * as apiClientService from '../../../acessApi/SystemAdmin/apiClientService';
import { RoleChecker } from '../../../utility/RoleChecker';

const { Search } = Input;
const { Content, Footer } = Layout;
const { Text } = Typography;



class SourceList extends React.Component {
  constructor(props) {
      super(props);
      this.searchBy = (
        <Menu>
          <Menu.Item>
            <div onClick={
              () => {
              this.setState({
                searchBy : 'username'
              })}
            }> 
              username
            </div>
          </Menu.Item>
          <Menu.Item>
            <div onClick={
              () => {
              this.setState({
                searchBy : 'email'
              })}
            }> 
              e-mail
            </div>
          </Menu.Item>
          <Menu.Item>
            <div onClick={
              () => {
              this.setState({
                searchBy : 'client_name'
              })}
            }> 
              client name
            </div>
          </Menu.Item>
        </Menu>
      );
      this.columnData =
        [
          {
            width: 100,
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            render: text => <p>{text}</p>,
          },
          {
            width: 100,
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            render: text => <p>{text}</p>
          },
          {
            width: 200,
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            render: text => <p>{text}</p>
          },
          {
            width: 200,
            title: 'Connection Info',
            dataIndex: 'connection_info',
            key: 'connection_info',
            render: text => <p>{text}</p>,
          },
          {
            width: 40,
            title: 'Action',
            key: 'action',
            render: (text, record) => (
              <span>
                <Popover content={"Client Detail"} >
                  <Link to = { RouterConstant.SYSADMIN.SOURCE.detail + '/' + record.key } key={record.key} >
                    <Icon type="file-search" style={{margin : '5px', color : "#7a7a7a"}} />
                  </Link>
                </Popover>
              </span>
            ),
          },
        ];
      this.state = { 
        sourceListData: {}, 
        currentPage: 1, 
        totalPages: null, 
        totalData: null, 
        sourceList: null, 
        dataToBeDeleted : [],
        itemPerPage: 10, 
        lastupdate : {},
        size: ["10", "20", "30", "40"],
        selectedRowKeys: [],
        loading: false,
        searching: false,
        searchBy : "username",
        searchKey: null,
        // isAdmin : true,
        isAdmin : RoleChecker(),
        lang : {}
      };
    }
  

  async searchClient(searchkey){
    this.setState({
      totalData: await (await apiClientService.countSearch(this.state.searchBy, searchkey)).data.nexsoft_message.payload.data.additional_info,
      currentPage : 1
    })
    this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, searchkey)
  }

  async getSearch(pageLimit, currentPage, searchBy, searchkey) {
    this.setState({ sourceListData: await apiClientService.getSearchData(pageLimit, currentPage, searchBy, searchkey) });
    this.getIndexedSource()
  }

  async getData(pageLimit, currentPage) {
    this.setState({ sourceListData: await apiClientService.getSourceListData(pageLimit, currentPage) });
    this.getIndexedSource()
  }

  async componentDidMount() {
    this.setState({
      lang : await langService.getLang(localStorage.getItem("language")),
      totalData: await (await apiClientService.countTotalData()).data.nexsoft_message.payload.data.additional_info,
      selectedRowKeys : [],
    })
    await this.getData(this.state.itemPerPage, this.state.currentPage)
    this.getIndexedSource()
  }

  async handleDelete(){
    if (this.state.selectedRowKeys.length != 0) {
      message.destroy();
      message.loading(this.state.lang.deletingData, 0);
      let dataToBeDeleted = []
      const selectedTemp = this.state.selectedRowKeys
      await selectedTemp.map( async id =>{
        dataToBeDeleted.push(
          {
            id : parseInt(id),
            record_meta_data: {
                    last_updated_at: this.state.lastupdate[`${id}`]
            }
          }
        )
      })
      const response = (await apiClientService.deleteMultiple(dataToBeDeleted));
      message.destroy();
      if (response.data.nexsoft_message.payload.data.error_code === "Oke") {
        message.success(this.state.lang.apiClientDeleted);
        this.setState({isFetching: false});
        this.componentDidMount()
      } else {
        message.error(this.state.lang.errorDeleting);
        this.setState({isFetching: false});
        this.componentDidMount()
      }
    }else{
      message.error(this.state.lang.noApi);
    }
  }

  onPageChange = async page => {
    await this.setState({
      currentPage: page,
    });
    if (this.state.searching == true){
      this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, this.state.searchKey)
    } else {
      this.getData(this.state.itemPerPage, this.state.currentPage, this.state.filterBy)
    }
  };

  handleSearchSubmit = async value => {
    if (value === null || value === "") {
      this.setState({
        searching : false
      })
      this.componentDidMount()
    } else {
      this.searchClient(value)
      this.setState({
        searchKey : value,
        searching : true
      })
    }
  }

  onShowSizeChange = async (current, pageSize) => {
    await this.setState({
      itemPerPage : pageSize,
      currentPage : 1
    });
    if (this.state.searching == true){
      this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, this.state.searchKey)
    } else {
      this.getData(this.state.itemPerPage, this.state.currentPage, this.state.filterBy)
    }
  }

  getIndexedSource() {
    let result = [];
    let lastupdated = {}
    let data = []
    if ( this.state.sourceListData != null ) {
      data = this.state.sourceListData.data.nexsoft_message.payload.data.additional_info
      data.map(results => {
        result.push({
          key: `${results.id}`,
          username: `${results.username}`,
          email: `${results.email}`,
          clientName: `${results.client_name}`,
          connection_info: `${results.connection_info}`,
        });
        lastupdated[`${results.id}`] = `${results.record_meta_data.last_updated_at}`
      });
      this.setState({
        sourceList: result,
        lastupdate: lastupdated
      });
    } else {
      this.setState({
        sourceList: result
      });
    }
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      width : '5px'
    };
    const pagination = {
      position: 'top', 
      defaultCurrent: this.state.currentPage,
      total: this.state.totalData, 
      onChange: this.onPageChange,
      showSizeChanger: true, 
      onShowSizeChange: this.onShowSizeChange, 
      pageSizeOptions: ["10", "20", "30", "40"],
      current : this.state.currentPage,
    };
    // if (this.state.isAdmin == true) {
      return (
        <Fragment >
          <Content >
            <Row>
              <Card style={{ borderRadius: '10px', boxShadow: '5px 5px 20px #888888', minHeight: '800px', margin: '15px', height: '80%'}}>
                <Row style={{ margin: '5px' }}>
                  <Col>
                    <PageTitle TitleText={this.state.lang.title}/>
                  </Col>
                </Row>
                <Row >
                  <Col span={4}>
                    <Search style={{margin: '5px'}}
                      placeholder={this.state.lang.searchPlaceHolder}
                      allowClear
                      onSearch={value => this.handleSearchSubmit(value)}
                    />
                  </Col>
                  <Col span={4}>
                      <Dropdown overlay={this.searchBy}>
                          <Button style={{margin:'5px', marginLeft: '15px'}}>
                            <GetSearchText searchstate={this.state.searchBy} /><Icon type="down" />
                          </Button>
                      </Dropdown>
                  </Col>
                  <Col span={16} style={{ textAlign: 'right' }}>
                    {
                      (
                        RoleChecker() == true ?
                        <Fragment>
                          <Popconfirm
                            disabled={!this.state.isAdmin}
                            title={this.state.lang.confirmDelete}
                            onConfirm={() => this.handleDelete()}
                            okText={this.state.lang.yes}
                            cancelText={this.state.lang.no}
                            >
                            <Button 
                            style={{margin : '5px', width : '135px'}}
                            disabled={!this.state.isAdmin}>
                              {this.state.lang.delete}
                            </Button>
                          </Popconfirm>
                          <AddButton link={RouterConstant.SYSADMIN.SOURCE.create} text={this.state.lang.add} disabled={!this.state.isAdmin}/>
                        </Fragment>
                        : ""
                      )
                    }
                  </Col>
                </Row>
                <Row>
                    <Table
                      rowSelection={rowSelection} 
                      dataSource={this.state.sourceList}
                      columns={this.columnData}
                      onChange={this.handleTableChange}
                      pagination={pagination}
                      scroll={{y: true }}
                      size="small"
                    />
                </Row>
              </Card>
            </Row>
          </Content>
        </Fragment>
      )
  }
}

export default SourceList;
