import React, { Fragment } from 'react';
import 'antd/dist/antd.css';
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col, 
  message,
  Modal
} from 'antd';
import { RouterConstant } from '../../../enum/routerConstant';
import PageTitle from '../../pageTitle';
import backlogo from '../../../assets/icon/back-icon.svg'
import { Link } from 'react-router-dom';
import AddButton from '../../addButton';
import * as destinationGroupService from '../../../acessApi/SystemAdmin/destinationGroupService'
import {AccessChecker} from '../../../utility/AccessChecker'

const { confirm } = Modal;

class EditGroupForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle : null,
            groupName : null,
            lastUpdated : null,
            isFetching: false,
            confirmDirty: false,
            autoCompleteResult: [],
        }
    }

  
    componentDidMount(){
        this.getData()
    }

    async getData(){
        const id = this.props.match.params.id;
        let groupData = []
        groupData = await destinationGroupService.getGroupInfo(id)
        const data = await groupData.data.nexsoft_message.payload.data.additional_info
        if (data.Group_name != null && data.username != "") {
            this.setState({
                pageTitle : <PageTitle TitleText={data.groupName} titleColor="black"/>,
                id : data.Id,
                groupName : data.Group_name,
                principal : data.Principal_id,
                lastUpdated : data.record_meta_data.last_updated_at
            })
        }else{
            this.setState({
                pageTitle : <PageTitle TitleText="No Data" titleColor="black"/>
            })
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          confirm({
            title: 'Are you sure to update destination group?',
            content: 'If destination group is updated maybe it will cause error on all client that related to this destination group.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk : ()=>this.postData(values)
          });
        }
        });
    };
    
    goback(){
        window.history.back();
    }

    async postData(data) {
        message.destroy();
        message.loading("Submitting your data, sit tight", 0);
        this.setState({isFetching: true});
        const response = (await destinationGroupService.updateGroupData(data));
        message.destroy();
        if (response.data.nexsoft_message.payload.data.error_code === "Oke") {
        message.success("Api client has been updated");
        } else {
        message.error("Error encountered from server");
        }
        this.getData()
        this.setState({isFetching: false});
    }

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

  render() {
    AccessChecker()
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {}
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }
    return (
      <Card style={{borderRadius : '10px', boxShadow :'5px 5px 20px #888888', minHeight: '600px'}}>
        <Row>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
              <Row style={{margin : '5px'}}>
                  <Col span={1} style={{lineHeight : '30px', textAlign: 'center'}}>
                    <a onClick={ () => this.goback() }>
                      <img src={backlogo} alt="backlogo" style={{width: '18px'}}></img>
                    </a>
                  </Col>
                <Col span={10} style={{lineHeight : '30px'}}>
                  <PageTitle TitleText={"Edit " + this.state.groupName}/>
                </Col>
                <Col span={13} style={{textAlign : 'right'}}>
                  {/* <AddButton text="Cancel" link={RouterConstant.SYSADMIN.GROUP.list} /> */}
                  <Button htmlType="submit" style={{backgroundColor : '#5493FF', color: 'white', width: '135px', margin:"5px"}}>
                      Save
                  </Button>
                </Col>
              </Row>
              <Row style={{fontSize : '16px', fontWeight: 'bold', margin :'10px'}}>
                  <Form.Item label="Group Name">
                  {getFieldDecorator('groupName', {
                      initialValue: this.state.groupName,
                      rules: [
                      {
                          required: true,
                          message: 'Please input Group Name!',
                      },
                      ],
                      value : this.state.groupName
                  })(<Input placeholder={this.state.groupName} />)}
                  </Form.Item>
                  {getFieldDecorator('lastUpdated', {initialValue: this.state.lastUpdated})}
                  {getFieldDecorator('id', {initialValue: this.state.id})}
              </Row>
          </Form>
        </Row>
      </Card>
    );
  }
}

const EditDestinationGroup = Form.create({ name: 'add' })(EditGroupForm);

export default EditDestinationGroup;
          