/**
 * Provide properties value for router path.
 */
export const RouterConstant = Object.freeze({
    SYSADMIN: {
        HOME : {
            home : "/apiserver/home"
        },
        SOURCE: {
            list: "/apiserver/source/list",
            detail: "/apiserver/source/detail",
            delete: "/apiserver/source/delete",
            create: "/apiserver/source/create",
            edit: "/apiserver/source/edit",
            changePassword: "/apiserver/source/change_password"
        },
        GROUP: {
            create: "/apiserver/destination_group/create",
            list: "/apiserver/destination_group/list",
            detail: "/apiserver/destination_group/detail",
            delete: "/apiserver/destination_group/delete",
            add: "/apiserver/destination_group/add",
            edit: "/apiserver/destination_group/edit"
        },
        JOB: {
            list: "/apiserver/job_process/list",
            detail: "/apiserver/job_process/detail",
        },
        TYPE: {
            create: "/apiserver/data_type/create",
            list: "/apiserver/data_type/list",
            detail: "/apiserver/data_type/detail",
            delete: "/apiserver/data_type/delete",
            edit: "/apiserver/data_type/edit"
        },
        CONFIGURATION: {
            list: "/apiserver/configuration/list",
            detail: "/apiserver/configuration/detail",
            edit: "/apiserver/configuration/edit"
        },
        HOUSEKEEPING: {
            history: "/apiserver/configuration/history",
            deletedHistory: "/apiserver/configuration/deletedHistory",
        }
    }
});