import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

class AddButton extends React.Component {
    render() {
        return (
            <Link to={this.props.link}>
                <Button style={{margin : '5px', width : '135px'}}
                      disabled={this.props.disabled}>{this.props.text}</Button>
            </Link>
        )
    }
}

export default AddButton;
