import React from 'react';
import 'antd/dist/antd.css';
import {
  Form,
  Input,
  Icon,
  Button,
  Card,
  message,
  Layout
} from 'antd';
import { loginService } from '../../acessApi/SystemAdmin/loginService';
import axioslogin from 'axios';

const { Content, Footer } = Layout;
var md5 = require('md5')

class LoginForm extends React.Component{
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
    };

    handleSubmit = e =>  {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
              this.loginprocess(values.username, values.password)
          }
        });
    };

    async loginprocess(username, password){
        message.destroy();
        message.loading("Logging in", 0);
        const result = await loginService(username, password)
        if (result.data.nexsoft_message.payload.data.error_message === "Success") {
            localStorage.setItem('user_name', result.data.nexsoft_message.payload.data.additional_info.user_name)
            localStorage.setItem('password', password)
            localStorage.setItem('user_id', result.data.nexsoft_message.payload.data.additional_info.user_id)
            localStorage.setItem('principal_id', "Univenus")
            localStorage.setItem('language', "en")
            localStorage.setItem('role', md5(result.data.nexsoft_message.payload.data.additional_info.role_name) )
            localStorage.setItem('data_type', "")
            localStorage.setItem('file_name', "")
            localStorage.setItem('destination', 0)
            localStorage.setItem('sender', 0)
            localStorage.setItem('status', "all")
            localStorage.setItem('dateFrom', "")
            localStorage.setItem('dateTo', "")
            window.location.reload()
        } else {
            message.destroy();
            message.error("Login Failed, Username or password is incorrect");
        }
    }

    render(){
        axioslogin.interceptors.response.use((response) => {
            // Do something with response data
            return response;
        }, (error) => {
            // Do something with response error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 401) {
                    message.destroy()
                    message.error("Username or password are incorrect")
                } else if (error.response.status === 500) { 
                    message.destroy()
                    message.error("Error Encountered From Server")
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  message.error("Server is Offline");
            } else {
                // Something happened in setting up the request that triggered an Error
                // this.setState({message: error.message});
                console.log('Error', error.message);
            }
            // this.setState({snackbarOpen: true});
            return Promise.reject(error);
        })
        const { getFieldDecorator } = this.props.form;
            return(
                <Layout>
                    <Content>
                        <Card title="Login" style={{marginTop : '10%', marginLeft : "35%", marginRight : "35%", marginBottom: '30%'}}>
                            <Form onSubmit={this.handleSubmit} className="login-form">
                                <Form.Item>
                                {getFieldDecorator('username', {
                                    rules: [{ required: true, message: 'Please input your username!' }],
                                })(
                                    <Input
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Username"
                                    />,
                                )}
                                </Form.Item>
                                <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: 'Please input your Password!' }],
                                })(
                                    <Input
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="Password"
                                    />,
                                )}
                                </Form.Item>
                                <Form.Item >
                                    <Button type="primary" htmlType="submit">
                                        Login
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Content>
                </Layout>
        )
    }
}

const LoginPage = Form.create({ name: 'register' })(LoginForm);
export default LoginPage