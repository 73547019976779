const en = 
    {   
        username: "Username",
        principalId : "Principal ID",
        clientName : "Client Name",
        clientCode : "API Client Code",
        serverID : "Server Id",
        email : "E-Mail",
        address : "Address",
        phone : "Phone Number",
        connectionInfo : "Connection Info",
        noData : "No Data",
        resetingMac : "Reseting server address",
        resetSuccess : "Server Id has been reset!",
        errorResetting : "Error encountered from server",
        resetServer : "Reset Server Id",
        changePassword : "Change Password",
        edit : "Edit",
        confirmReset : "Are you sure to reset server id?",
        yes : "Yes",
        no : "No",
    }
export default en