import React, { Fragment } from 'react';
import { lang } from '../enum/language/searchBy';


class GetSearchText extends React.Component{
    render(){
        switch (this.props.searchstate) {
            case "username":
                return <span>
                    {lang.searchBy} username
                </span>
                break;
            case "code":
                return <span>
                    {lang.searchBy} code
                </span>
                break;
            case "email":
                return <span>
                    {lang.searchBy} e-mail
                </span>
                break;
            case "client_name":
                return <span>
                    {lang.searchBy} client name
                </span>
                break;
        }
    }
}

export default GetSearchText
    