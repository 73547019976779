import React, { Fragment } from 'react';
import { Table, Col, Row, Card, Layout, Popconfirm, message, Icon, Popover, Button, Modal, Input} from 'antd';
import AddButton from '../../addButton';
import { RouterConstant } from '../../../enum/routerConstant';
import SearchBar from '../../SearchBar';
import { Link } from 'react-router-dom';
import PageTitle from '../../pageTitle';
import * as dataTypeService from '../../../acessApi/SystemAdmin/dataTypeService';
import { RoleChecker } from '../../../utility/RoleChecker';

const { Search } = Input;
const { Content, Footer } = Layout;
const { confirm } = Modal;

class DataType extends React.Component {
  constructor(props) {
      super(props);
      if (RoleChecker() == true) {
        this.columndata = [
          {
            width: 700,
            title: 'Data Type Name',
            dataIndex: 'typename',
            key: 'typename',
            render: text => <p>{text}</p>,
          },
          {
            width: 100,
            title: 'Action',
            key: 'action',
            render: (text, record) => (
              <span>
                <Popover content={"Edit data Type"}>
                  <Link to = { RouterConstant.SYSADMIN.TYPE.edit + '/' + record.key } key={record.key} >
                    <Icon type="edit" theme="filled" style={{margin : '5px', color : "#7a7a7a", fontSize:"16px"}} />
                  </Link>
                </Popover>
              </span>
            ),
          },
        ];
      } else {
        this.columndata = [
          {
            width: 700,
            title: 'Data Type Name',
            dataIndex: 'typename',
            key: 'typename',
            render: text => <p>{text}</p>,
          },
        ];
      }
      this.state = { 
        dataTypeData: null, 
        currentPage: 1, 
        totalPages: null, 
        totalData: null, 
        dataList: null,
        dataToBeDeleted : [],
        itemPerPage: 10,
        lastupdate : {},
        selectedRowKeys: [],
        searching: false,
        searchBy : "datatypename",
        searchKey: null,
        loading: false,
      };
    }
  

  async getData(pageLimit, currentPage) {
    this.setState({ dataTypeData: await dataTypeService.getDataTypeList(pageLimit, currentPage) });
    this.getIndexedDataType()
  }

  async componentDidMount() {
    await this.setState({
      totalData: await (await dataTypeService.countTotalData()).data.nexsoft_message.payload.data.additional_info.count,
      selectedRowKeys : []
    })
    await this.getData(this.state.itemPerPage, this.state.currentPage)
    this.getIndexedDataType()
  }

  async handleDelete(){
      message.destroy();
      message.loading("Deleting Data", 0);
      let dataToBeDeleted = []
      const selectedTemp = this.state.selectedRowKeys
      await selectedTemp.map( async id =>{
        dataToBeDeleted.push(
          {
            id : parseInt(id),
            record_meta_data: {
              last_updated_at: this.state.lastupdate[`${id}`]
            }
          }
        )
      })
      const response = (await dataTypeService.deleteMultiple(dataToBeDeleted));
      message.destroy();
      if (response.data.nexsoft_message.payload.data.error_code === "Oke") {
        message.success("Data Type has been deleted!");
        this.setState({isFetching: false});
        this.componentDidMount()
      } else {
        message.error("Error encountered from server");
        this.setState({isFetching: false});
        this.componentDidMount()
      }
    }


  onPageChange = async page => {
    await this.setState({
      currentPage: page,
    });
    if (this.state.searching == true){
      this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, this.state.searchKey)
    } else {
      this.getData(this.state.itemPerPage, this.state.currentPage)
    }
  };

  onShowSizeChange = async (current, pageSize) => {
    await this.setState({
      itemPerPage : pageSize,
      currentPage : 1
    });
    console.log(this.state.searching)
    if (this.state.searching == true){
      this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, this.state.searchKey)
    } else {
      this.getData(this.state.itemPerPage, this.state.currentPage)
    }
  }

  getIndexedDataType() {
    let result = [];
    let lastupdated = {}
    let data = []
    data = this.state.dataTypeData.data.nexsoft_message.payload.data.additional_info
    try {
      data.map(results => {
        result.push({
          key: `${results.Id}`,
          typename: `${results.Data_type}`,
        });
        lastupdated[`${results.Id}`] = `${results.record_meta_data.last_updated_at}`
      });
      this.setState({
        dataList: result,
        lastupdate: lastupdated
      });
    } catch (error) {
      this.setState({
        dataList: result
      });
    }
  }
  
  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  showDeleteAlert = () => {
    if (this.state.selectedRowKeys.length != 0) {
      confirm({
        title: 'Are you sure delete data type?',
        content: 'If data type deleted maybe it will cause error on all client that related to this data type. ',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk : ()=>this.handleDelete()
      });
    }else{
      message.error("No Destination group selected");
    }
  }

  handleSearchSubmit = async value => {
    this.setState({
      searching : false
    })
    if (value === null || value === "") {
      this.componentDidMount()
    } else {
      this.searchClient(value)
      await this.setState({
        searchKey : value,
        searching : true
      })
    }
  }

  async searchClient(searchkey){
    this.setState({
      totalData: await (await dataTypeService.countSearch(this.state.searchBy, searchkey)).data.nexsoft_message.payload.data.additional_info,
      currentPage : 1
    })
    this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, searchkey)
  }

  async getSearch(pageLimit, currentPage, searchBy, searchkey) {
    this.setState({ dataTypeData: await dataTypeService.getSearchData(pageLimit, currentPage, searchBy, searchkey) });
    this.getIndexedDataType()
  }

  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      width : '5px'
    };
    const pageConfig = {
      position: 'top', 
      defaultCurrent: this.state.currentPage,
      total: this.state.totalData, 
      onChange: this.onPageChange,
      showSizeChanger: true, 
      onShowSizeChange: this.onShowSizeChange, 
      current : this.state.currentPage,
      pageSizeOptions: ["10", "20", "30", "40"],
    };
    return (
      <Fragment >
        <Content >
          <Row>
            <Card style={{ borderRadius: '10px', boxShadow: '5px 5px 20px #888888', minHeight: '800px', margin: '15px', height: '80%' }}>
              <Row style={{ margin: '5px' }}>
                <Col span={4}>
                  <PageTitle TitleText="Data Type" />
                </Col>
              </Row>
              <Row style={{ marginBottom: '15px' }}>
                <Col span={4}>
                  <Search style={{margin: '5px'}}
                    placeholder={"Search by data type name"}
                    allowClear
                    onSearch={value => this.handleSearchSubmit(value)}
                  />
                </Col>
                <Col span={20} style={{ textAlign: 'right' }}>
                  {
                    (
                      RoleChecker() == true ?
                      <Fragment>
                        <Popconfirm
                          title="Are you sure delete selected data type?"
                          onConfirm={() => this.handleDelete()}
                          okText="Yes"
                          cancelText="No"
                          >
                          <Button style={{margin : '5px', width : '135px'}}>
                            Delete
                          </Button>
                        </Popconfirm>
                        <AddButton link={RouterConstant.SYSADMIN.TYPE.create} text="Add" />
                      </Fragment>
                      : ""
                    )
                  }
                </Col>
              </Row>
              <Row>
                  <Table
                    rowSelection={rowSelection} 
                    dataSource={this.state.dataList}
                    columns={this.columndata}
                    onChange={this.handleTableChange}
                    pagination={pageConfig}
                    scroll={{ y : true }}
                    size="small"
                  />
              </Row>
            </Card>
          </Row>
        </Content>
      </Fragment>
    )
  }
}

export default DataType;
