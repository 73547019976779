import React from 'react';

function FooterLayout() {
    return (
        <div>
             <h1>Univenus API</h1>
        </div>
    );
  }
  
  export default FooterLayout;