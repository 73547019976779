import React from 'react';
import { Layout } from 'antd';
import NavBarItem from "../navigation/index";
import FooterItem from "../footerLayout/index";
import './index.css'
import App from '../../App';
import { BrowserRouter as Router } from 'react-router-dom';
import SideBar from '../sideBar';
const { Content, Sider, Footer } = Layout;

class MasterLayout extends React.Component {
  render() {
    return (
      <Router>
        <Layout >
            <React.Fragment>
                <Sider className="side-layout" width = { 60 } style={{height : '100vh'}}>
                  <SideBar/>
                </Sider>
                <Layout>
                  <Content style={{ margin: '15px', height: '100%' }}>
                    <App />
                  </Content>
                </Layout>
            </React.Fragment>
        </Layout>
      </Router>
    );
  }
}
export default MasterLayout;