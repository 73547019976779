import React, { Fragment } from 'react';
import {  Card, Col, Row, Button, Pagination, Icon, Table, Popover, Divider, message} from 'antd';
import { RouterConstant } from '../../../enum/routerConstant';
import backlogo from '../../../assets/icon/back-icon.svg'
import SearchBar from '../../SearchBar';
import PageTitle from '../../pageTitle';
import StatusIcon from '../../../common/jobDetailStatusIcon'
import DownloadStatus from '../../../common/downloadStatusIcon'
import * as jobProcessService from '../../../acessApi/SystemAdmin/jobProcessService'
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import './index.css';
import AddButton from '../../addButton';
import { RoleChecker } from '../../../utility/RoleChecker'


class MonitoringDetail extends React.Component{
    constructor(props) {
        super(props);
        this.columndata = [
          {
            width: 75,
            title: 'Destination',
            dataIndex: 'destination',
            key: 'destination',
            render: text => <p>{text}</p>,
          },
          {
            width: 75,
            title: 'Notify Status',
            dataIndex: 'notifyStatus',
            key: 'notifyStatus',
            render: text => <StatusIcon message={text} />,
          },
          {
            width: 100,
            title: 'Notify Date',
            dataIndex: 'notifyDate',
            key: 'notifyDate',
            render: text => <p>{text}</p>,
          },
          {
            width: 75,
            title: 'Download Status',
            dataIndex: 'downloadStatus',
            key: 'downloadStatus',
            render: text => <StatusIcon message={text} />,
          },
          {
            width: 100,
            title: 'Download Date',
            dataIndex: 'downloadDate',
            key: 'downloadDate  ',
            render: text => <p>{text}</p>,
          },
          {
            width: 75,
            title: 'Download Time',
            dataIndex: 'downloadTime',
            key: 'downloadTime',
            render: text => <p>{text}</p>,
          },
          {
            width: 75,
            title: 'Processing Status',
            dataIndex: 'processingStatus',
            key: 'processingStatus',
            render: text => <StatusIcon message={text} />,
          },
          {
            width: 100,
            title: 'Processing Date',
            dataIndex: 'processingDate',
            key: 'processingDate  ',
            render: text => <p>{text}</p>,
          },
          // {
          //   width: 50,
          //   title: 'Action',
          //   key: 'action',
          //   render: (text, record) => (
          //     <span>
          //       <Popover content={"View Client"}>
          //         <Link to = { RouterConstant.SYSADMIN.SOURCE.detail + '/' + record.key } key={record.key} >
          //           <Icon type="file-search" style={{margin : '5px', color : "#7a7a7a"}} />
          //         </Link>
          //       </Popover>
          //     </span>
          //   ),
          // },
        ];
        this.state = {
          detailHeader : null,
          detailData: null, 
          currentPage: 1, 
          totalPages: null, 
          sourceList: null, 
          totalData: null, 
          itemPerPage: 10, 
          pageTitle : null
        };
      }
      
    async getData(pageLimit, currentPage) {
      this.setState({ detailData: await jobProcessService.getDataMonitoringDetail(this.props.match.params.id ,currentPage, pageLimit) });
      this.mapData()
    }

    async downloadData(){
      if (RoleChecker() === true) {
        try {
          const text = await jobProcessService.downloadFile(this.state.detailData.data.nexsoft_message.payload.data.additional_info.data.uuid)
          console.log(text)
          var data = new Blob([text.data], {type: 'application/zip'});
          var url = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.state.detailData.data.nexsoft_message.payload.data.additional_info.data.uuid + ".zip");
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        } catch {
          message.error("Error encountered from server.");
        }
      } else {
        message.error("You dont have the access to download");
      }
    }

    async mapData() {
      let result = []
        let data = await this.state.detailData.data.nexsoft_message.payload.data.additional_info
        if (data != null) {
            this.setState({
              detailHeader : 
              <Fragment>
                <Row>
                  <a onClick={() => { this.goback()}}>
                    <Col span={1} style={{lineHeight : '30px', textAlign: 'center'}}>
                      <img src={backlogo} alt="backlogo" style={{width: '18px'}}></img>
                    </Col>
                  </a>
                  <Col span={15}>
                    <PageTitle TitleText={data.data.file_name} titleColor='black'/>
                  </Col>
                  <Col span={8} style={{textAlign:'right'}}>
                    <PageTitle TitleText={"Source : " + data.source_process.source_username}  titleColor='black'/>
                  </Col>
                </Row>
                <Row >
                <Col span={10}>
                  <Button onClick={() => this.downloadData()} style={{margin : '5px', width : '270px'}}>
                    Download Data
                  </Button>
                </Col>
                <Col span={14} style={{textAlign:'right'}}>
                  <PageTitle TitleText={"UUID : " + data.data.uuid} titleColor='black'/>
                </Col>
                </Row>
              </Fragment>
            }) 
        }else{
            this.setState({
                detailHeader : 
                <Row style={{marginLeft:'20px'}}>
                  <PageTitle TitleText="No Data" titleColor="black"/>
                </Row>
            })
        }
        if (data.destination_process != null) {
          data.destination_process.map((results)=>
            result.push({
              key : `${results.destination_username}`+`${results.download_date}`,
              destination: `${results.destination_username}`,
              notifyStatus: `${results.notify_status}`,
              notifyMessage: `${results.notify_message}`,
              notifyDate: (results.notify_date === "" ? `${results.notify_date}` : `${results.notify_date}`.split("T")[0] + " " + `${results.notify_date}`.split("T")[1]),
              downloadStatus: `${results.download_status}`,
              downloadMessage: (results.download_message === "" ? "-" : `${results.download_message}`),
              downloadDate: (results.download_date === "" ? "-" : `${results.download_date}`.split("T")[0] + " " + `${results.download_date}`.split("T")[1]),
              processingStatus: `${results.processing_status}`,
              processingMessage: (results.processing_message === "" ? "-" : `${results.processing_message}`),
              processingDate: (results.processing_date === "" ? "-" : `${results.processing_date}`.split("T")[0] + " " + `${results.processing_date}`.split("T")[1]),
              downloadTime : (results.download_time === "" ? "-" : `${results.download_time}`),
            }),
          )
          this.setState({
            jobData : result
          })
        } else {
          this.setState({
            jobData : result
          })
        }
    }

    goback(){
      window.history.back();
    }

    
    onChange = page => {
      this.setState({
        currentPage: page,
      });
      this.getData(this.state.itemPerPage, page)
    };

    goback(){
      window.history.back();
    }

    onShowSizeChange = (current, pageSize) => {
      this.setState({
        itemPerPage: pageSize
      });
      this.getData(this.state.itemPerPage, this.state.currentPage)
    }

    async componentDidMount(){
      await this.setState({
        totalData: await (await jobProcessService.countDataMonitoringDetail(this.props.match.params.id)).data.nexsoft_message.payload.data.additional_info
      })
      await this.getData(this.state.itemPerPage, this.state.currentPage)
      this.mapData()
    }
    render(){
      const pagination = {
        position: 'top', defaultCurrent: this.state.currentPage,
        total: this.state.totalData, onChange: this.onChange,
        showSizeChanger: true, onShowSizeChange: this.onShowSizeChange, pageSizeOptions : ["10","25","50"]
      };
      return(
        <Fragment >
          <Row>
            <Card style={{ borderRadius: '10px', boxShadow: '5px 5px 20px #888888', minHeight: '800px', margin: '15px' }}>
              <Row style={{ margin: '5px' }}>
                {this.state.detailHeader}
              </Row>
              <Row>
                <div>
                  <Table
                    dataSource={this.state.jobData}
                    columns={this.columndata}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    expandRowByClick = "true"
                    expandedRowRender={
                      record => 
                      <Fragment>
                        <Row>
                          <Col span={3}>
                            Notify Message
                          </Col>
                          <Col span={1}>
                            :
                          </Col>
                          <Col span={20}>
                            {record.notifyMessage}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={3}>
                            Download Message
                          </Col>
                          <Col span={1}>
                            :
                          </Col>
                          <Col span={20}>
                          {record.downloadMessage}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={3}>
                            Processing Message
                          </Col>
                          <Col span={1}>
                            :
                          </Col>
                          <Col span={20}>
                          {record.processingMessage}
                          </Col>
                        </Row>
                      </Fragment>
                    }
                    scroll={{ y: true }}
                    size="small"
                  />
                </div>
              </Row>
            </Card>
          </Row>
        </Fragment>
      )
    }
}



export default withRouter(MonitoringDetail);
