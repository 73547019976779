import React, { Fragment } from 'react';
import { Table, Card, Col, Row, Pagination, Popover, Icon, Popconfirm, message, Divider, Button, Modal} from 'antd';
import AddButton from '../../addButton';
import SearchBar from '../../SearchBar';
import { RouterConstant } from '../../../enum/routerConstant';
import * as configurationService from '../../../acessApi/SystemAdmin/configurationService'
import PageTitle from '../../pageTitle';
import { Link, Router } from 'react-router-dom';
import {AccessChecker} from '../../../utility/AccessChecker'

const { confirm } = Modal;

class ConfigurationList extends React.Component {  
  constructor(props) {
    super(props);
    this.columndata = [
      {
        width: 400,
        title: 'Key',
        dataIndex: 'configurationKey',
        key: 'configurationKey',
        render: text => <p>{text}</p>,
      },
      {
        width: 400,
        title: 'Value',
        dataIndex: 'configurationValue',
        key: 'configurationValue',
        render: text => <p>{text}</p>,
      },
      {
        width: 100,
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Popover content={"Edit Configuration"}>
              <Link to = { RouterConstant.SYSADMIN.CONFIGURATION.edit + '/' + record.key } key={record.key} >
                <Icon type="edit" theme="filled" style={{margin : '5px', color : "#7a7a7a", fontSize:"16px"}} />
              </Link>
            </Popover>
          </span>
        ),
      },
    ];
    this.state = { 
      listData: null, 
      currentPage: 1, 
      totalPages: null, 
      sourceList: null, 
      totalData: null, 
      itemPerPage: 10, 
      groupData : null,
      selectedRowKeys: [],
      size: ["10", "20", "30", "40"],
      lastupdate : {},
      loading: false
    };
  }

  async getData(pageLimit, currentPage) {
    this.setState({ 
      listData: await configurationService.getConfigurationList(currentPage, pageLimit) 
    });
    this.mapData()
  }

  onChange = page => {
    this.setState({
      currentPage: page,
    });
    this.getData(this.state.itemPerPage, page)
  };

  onShowSizeChange = (current, pageSize) => {
    this.setState({
      itemPerPage: pageSize
    });
    this.componentDidMount()
  }

  async mapData(){
    let result = []
    // let lastupdated = {}
    let data = []
    data = await this.state.listData.data.nexsoft_message.payload.data.additional_info
    if (data != null) {
      data.map(results => {
        result.push({
          key: `${results.id}`,
          configurationKey: `${results.setting_key}`,
          configurationValue: `${results.setting_value}`,
        });
        // lastupdated[`${results.Id}`] = `${results.Record_meta_data.Last_updated_at}`
      });
      this.setState({
        groupData: result,
        // lastupdate: lastupdated
      });
    }else{
      this.setState({
        groupData : result
      })  
    }
  }
  

  async componentDidMount(){
    await this.setState({
      totalData: await (await configurationService.countTotalData()).data.nexsoft_message.payload.data.additional_info
    })
    await this.getData(this.state.itemPerPage, this.state.currentPage)
  }
  
  render(){
    AccessChecker()
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      width : '10px'
    };
    const pagination = {
      position: 'top', defaultCurrent: this.state.currentPage,
      total: this.state.totalData, onChange: this.onChange,
      showSizeChanger: true, onShowSizeChange: this.onShowSizeChange, pageSizeOptions : this.state.size
    };
    return(
      <Fragment >
        <Row>
          <Card style={{ borderRadius: '10px', boxShadow: '5px 5px 20px #888888', minHeight: '800px', margin: '15px' }}>
            <Row style={{ margin: '5px' }}>
              <Col span={6}>
                <PageTitle TitleText="Server Configuration"/>
              </Col>
            </Row>
            <Row >
              <Col span={4}>
                {/* <SearchBar style={{margin: '5px'}} /> */}
              </Col>
              <Col span={20} style={{ textAlign: 'right' }}>
                {/* <Link to={RouterConstant.SYSADMIN.CONFIGURATION.edit}>
                  <Button style={{margin : '5px', width : '135px'}}>
                    Edit
                  </Button>
                </Link> */}
              </Col>
            </Row>
            <Row>
                <Table
                  dataSource={this.state.groupData}
                  columns={this.columndata}
                  pagination={pagination}
                  onChange={this.handleTableChange}
                  scroll={{ y: true }}
                  size="small"
                />
            </Row>
          </Card>
        </Row>
      </Fragment>
    )   
  }
}

export default ConfigurationList;
