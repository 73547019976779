import React from 'react';
import 'antd/dist/antd.css';
import { RouterConstant } from '../../../enum/routerConstant';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message
} from 'antd';
import PageTitle from '../../pageTitle';
import * as destinationGroupService from '../../../acessApi/SystemAdmin/destinationGroupService'
import backlogo from '../../../assets/icon/back-icon.svg'
import { Link } from 'react-router-dom';
import AddButton from '../../addButton';
import axios from 'axios';
import {AccessChecker} from '../../../utility/AccessChecker'


class RegistrationForm extends React.Component {
  state = {
    isFetching: false,
    confirmDirty: false,
    autoCompleteResult: [],
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.postData(values)
      }
    });
  };

  async postData(data) {
    message.destroy();
    message.loading("Submitting your data, sit tight", 0);
    this.setState({isFetching: true});
    const response = (await destinationGroupService.createDestinationGroup(data));
    message.destroy();
    if (response.data.nexsoft_message.payload.data.error_code === "Oke") {
      message.success("New destination group has been created");
    } else {
      message.error("Error encountered from server");
    }
    this.props.form.resetFields();
    this.setState({isFetching: false});
  }


  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleWebsiteChange = value => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
    }
    this.setState({ autoCompleteResult });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    AccessChecker()
    const formItemLayout = {
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    return (
      <Row>
        <Card style={{borderRadius : '10px', boxShadow :'5px 5px 20px #888888', minHeight: '600px', margin: '15px'}}>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
              <Row style={{margin : '5px'}}>
                <Link to = { RouterConstant.SYSADMIN.GROUP.list }>
                  <Col span={1} style={{lineHeight : '30px', textAlign: 'center'}}>
                    <img src={backlogo} alt="logoutLogo" style={{width: '18px'}}></img>
                  </Col>
                </Link>
                <Col span={10} style={{lineHeight : '30px'}}>
                  <PageTitle TitleText="Add destination group"/>
                </Col>
                <Col span={13} style={{textAlign : 'right'}}>
                  {/* <AddButton text="Cancel" link={RouterConstant.SYSADMIN.GROUP.list} /> */}
                  <Button htmlType="submit" style={{backgroundColor : '#5493FF', color: 'white', width: '135px', margin:"5px"}}>
                      Save
                  </Button>
                </Col>
              </Row>
              <Row style={{fontSize : '16px', fontWeight: 'bold', margin :'10px'}}>
                <Form.Item label="Group Name">
                {getFieldDecorator('groupName', {
                    rules: [
                    {
                        required: true,
                        message: 'Please input Group Name!',
                    },
                    ],
                })(<Input />)}
                </Form.Item>
              </Row>
          </Form>
        </Card>
      </Row>
    );
  }
}

const AddNewGroup = Form.create({ name: 'add' })(RegistrationForm);

export default AddNewGroup;
          