import React, { Fragment } from 'react';
import {Icon} from 'antd'


class getStatusIcon extends React.Component{
    render(){
        switch (this.props.message) {
            case "inprogress":
                return <span>
                    <Icon type="sync" style={{color:'#f5e617'}}/> {this.props.message}
                </span>
                break;
            case "done":
                return <span>
                    <Icon type="check-circle" theme="filled" style={{color:'#4bb543'}}/> {this.props.message}
                </span>
                break;
            case "error":
                return <span>
                    <Icon type="close-circle" theme="filled" style={{color:'#ed4337'}}/> {this.props.message}
                </span>
                break;
            case "":
                return <span>
                    Not Processed
                </span>
                break;
        }
    }
}

export default getStatusIcon