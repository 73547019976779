const en = 
    {   
        searchBy: "Search By : ",
        title : "API Server Client",
        searchPlaceHolder : "Search",
        clientDetail : "Client Detail",
        deleteingData : "Deleting Data",
        apiClientDeleted : "API Server Client has been deleted!",
        errorDeleting : "Error encountered from server",
        noApi : "No Selected API Server Client",
        confirmDelete : "Are you sure to delete selected API Server Client?",
        yes : "Yes",
        no : "No",
        delete : "Delete",
        add : "Add",
    }
export default en