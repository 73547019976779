import * as baseApi from '../baseApi.js';
import * as headerFormat from '../../common/createNexsoftMessageHeader'
import {apiUrl}  from "../urlConstant";
const uuidv4 = require('uuid/v4');
const date = new Date();

export async function loginService(username, password) {
    const url = apiUrl + '/monitoring/login'
    const header = {
        "message_id": uuidv4().replace(/-/g, ''),
        "user_id": username,
        "password": password,
        "version":"1.0.0",
        "principal_id": "Univenus",
        "timestamp": (date.toISOString()).replace(/Z/g, ''),
        "action":{
           "class_name":"MONITORING",
           "type_name": "LOGIN"
        }
    }
    const payload = {
    }
    return baseApi.postData(url, header , payload)
}