import React from 'react';
import './index.css';
import 'antd/dist/antd.css';
import MasterLayout from '../Layout'
import LoginPage from '../login'
import TokenChecker from '../../utility/TokenChecker'

class MainApp extends React.Component {
  render() {
    if (TokenChecker() === true) {
      return(
        <MasterLayout />
      )
    } else {
      return(
        <LoginPage />
      )
    }
  }
}
export default MainApp;