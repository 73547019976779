import React from 'react';
import {Icon} from 'antd'


class downloadStatusIcon extends React.Component{
    render(){
        if (this.props.message === "") {
            return <span>
                Not Downloaded
                {/* <Icon type="close-circle" theme="filled" style={{color:'#ed4337'}}/> {this.props.message} */}
            </span>
        } else if (this.props.message === "being_processed") {
            return <span>
                <Icon type="sync" style={{color:'#f5e617'}}/> On process
            </span>
        } else if (this.props.message === "done") {
            return <span>
                <Icon type="check-circle" theme="filled" style={{color:'#4bb543'}}/> {this.props.message}
            </span>
        } else if (this.props.message === "error_processed"){
            return <span>
                <Icon type="close-circle" theme="filled" style={{color:'#ed4337'}}/> Error processed
            </span>
        }
    }
}

export default downloadStatusIcon