import {RoleChecker} from './RoleChecker'
import {Modal} from 'antd'

function redirectToHome(){
    window.location.replace("/");
}

export function AccessChecker(){
    if ( RoleChecker() === false ){
        Modal.error({
            title: 'Authentication Failed',
            content: 'You dont have the right to access this!',
            onOk : () => redirectToHome()
        }); 
    }
}