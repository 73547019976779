import React, { Fragment } from 'react';
import {  Card, Col, Row, Pagination, Icon, Popover, message, Divider, Table, Input, Popconfirm, Button, Dropdown, Menu } from 'antd';
import { RouterConstant } from '../../../enum/routerConstant';
import GetSearchText from "../../../common/getSearchByText"
import backlogo from '../../../assets/icon/back-icon.svg'
import PageTitle from '../../pageTitle';
import * as destinationGroupService from '../../../acessApi/SystemAdmin/destinationGroupService'
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import './index.css';
import {AccessChecker} from '../../../utility/AccessChecker'

const { Search } = Input;


class addClientToDestinationGroup extends React.Component{
    
    constructor(props) {
      super(props);this.columndata = [
        {
          width: 100,
          title: 'Username',
          dataIndex: 'username',
          key: 'username',
          render: text => <p>{text}</p>,
        },
        {
          width: 100,
          title: 'Client Name',
          dataIndex: 'clientName',
          key: 'clientName',
          render: text => <p>{text}</p>,
        },
        {
          width: 200,
          title: 'E-Mail',
          dataIndex: 'email',
          key: 'email',
          render: text => <p>{text}</p>,
        },
        {
          width: 200,
          title: 'Connection Info',
          dataIndex: 'connInfo',
          key: 'connInfo',
          render: text => <p>{text}</p>,
        },
        {
          width: 50,
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <span>
              <Popover content={"Client detail"}>
                <Link to = { RouterConstant.SYSADMIN.SOURCE.detail + '/' + record.key } key={record.key} >
                  <Icon type="file-search" style={{margin : '5px', color : "#7a7a7a", fontSize:"16px"}} />
                </Link>
              </Popover>
            </span>
          ),
        },
      ];this.searchBy = (
        <Menu>
          <Menu.Item>
            <div onClick={
              () => {
              this.setState({
                searchBy : 'username'
              })}
            }> 
              username
            </div>
          </Menu.Item>
          <Menu.Item>
            <div onClick={
              () => {
              this.setState({
                searchBy : 'email'
              })}
            }> 
              e-mail
            </div>
          </Menu.Item>
          <Menu.Item>
            <div onClick={
              () => {
              this.setState({
                searchBy : 'client_name'
              })}
            }> 
              client name
            </div>
          </Menu.Item>
        </Menu>
      );
      this.state = { 
        unsignedClient: null, 
        currentPage: 1, 
        totalPages: null, 
        sourceList: null, 
        totalData: null, 
        itemPerPage: 10, 
        groupData : null,
        isFetching : false,
        selectedRowKeys: [],
        loading: false,
        pageTitle : null, 
        searching: false,
        searchBy : "username",
        searchKey: null
      };
    }

    async searchClient() {
      await this.setState({
        totalData: await (await destinationGroupService.countSearchUnsigned(this.state.searchBy, this.state.searchKey, this.state.groupId)).data.nexsoft_message.payload.data.additional_info,
        currentPage: 1
      })
      await this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, this.state.searchKey, this.state.groupId)
    }

    async getSearch(pageLimit, currentPage, searchBy, searchkey, groupId) {
      this.setState({ unsignedClient: await destinationGroupService.getSearchUnsigned(pageLimit, currentPage, searchBy, searchkey, groupId) });
      this.mapData()
    }

    handleSearchSubmit = async value => {
      this.setState({
        searching : false
      })
      if (value === null || value === "") {
        this.componentDidMount()
      } else {
        await this.setState({
          searchKey : value,
          searching: true
        })
        this.searchClient()
      }
    }

  
    async getData(pageLimit, currentPage) {
      this.setState({ 
        unsignedClient: await destinationGroupService.getUnsignedClient(this.props.match.params.id, currentPage, pageLimit) 
      });
      this.mapData()
    }

    async signClient(){
      if (this.state.selectedRowKeys.length != 0) {
        message.destroy();
        message.loading("Unsigning Group", 0);
        let dataToBeDeleted = []
        const groupData = {
          id : this.state.unsignedClient.data.nexsoft_message.payload.data.additional_info.id,
          groupName : this.state.unsignedClient.data.nexsoft_message.payload.data.additional_info.group_name,
          lastUpdated : this.state.unsignedClient.data.nexsoft_message.payload.data.additional_info.record_meta_data.Last_updated_at,
        }
        const selectedTemp = this.state.selectedRowKeys
        await selectedTemp.map( async id =>{
          dataToBeDeleted.push(
            {
              id : parseInt(id),
            }
          )
        })
        const response = (await destinationGroupService.addClientToGroup(dataToBeDeleted, groupData));
        message.destroy();
        if (response.data.nexsoft_message.payload.data.error_code === "Oke") {
          message.success("Destination group are signed!");
          this.setState({isFetching: false});
          this.componentDidMount()
        } else {
          message.error("Error encountered from server");
          this.setState({isFetching: false});
          this.componentDidMount()
        }
      }else{
        message.error("No API client selected");
      }
    }
  
    onChange = async page => {
      await this.setState({
        currentPage: page,
      });
      if (this.state.searching == true) {
        this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, this.state.searchKey, this.state.groupId)
      } else {
        this.getData(this.state.itemPerPage, this.state.currentPage)
      }
    };
    
    onShowSizeChange = async (current, pageSize) => {
      await this.setState({
        itemPerPage: pageSize,
        currentPage: 1
      });
      if (this.state.searching == true) {
        this.getSearch(this.state.itemPerPage, this.state.currentPage, this.state.searchBy, this.state.searchKey, this.state.groupId)
      } else {
        this.getData(this.state.itemPerPage, this.state.currentPage)
      }
    }

    async mapData() {
        let arrayData = []
        const data = await this.state.unsignedClient.data.nexsoft_message.payload.data.additional_info.destinations
        if (data != null) {
            data.map((results)=>
              arrayData.push({
                key: `${results.id}`,
                username: `${results.username}`,
                clientName: `${results.client_name}`,
                email: `${results.email}`,
                connInfo: `${results.connection_info}`,
              })
            )
            this.setState({
              pageTitle :  this.state.unsignedClient.data.nexsoft_message.payload.data.additional_info.group_name,
              groupData : arrayData
            }) 
        }else{
            this.setState({
              pageTitle :  this.state.unsignedClient.data.nexsoft_message.payload.data.additional_info.group_name,
              groupData : arrayData
            })
        }
    }

    goback(){
      window.history.back();
    }

    async componentDidMount(){
      await this.setState({
        totalData: await (await destinationGroupService.countUnsignedClient(this.props.match.params.id)).data.nexsoft_message.payload.data.additional_info,
        selectedRowKeys : [],
        groupId : parseInt(this.props.match.params.id)
      })
      await this.getData(this.state.itemPerPage, this.state.currentPage)
    }

    onSelectChange = selectedRowKeys => {
      this.setState({ selectedRowKeys });
    };

    render(){
      AccessChecker()
      const { loading, selectedRowKeys } = this.state;
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        width : '10px'
      };
      const pagination = {
        position: 'top', 
        defaultCurrent: this.state.currentPage,
        total: this.state.totalData, 
        onChange: this.onPageChange,
        showSizeChanger: true, 
        onShowSizeChange: this.onShowSizeChange, 
        pageSizeOptions: ["10", "20", "30", "40"],
      };
        return(
        <Fragment >
          <Row>
            <Card style={{ borderRadius: '10px', boxShadow: '5px 5px 20px #888888', minHeight: '800px', margin: '15px' }}>
              <Row style={{ margin: '5px' }}>
                <a onClick={() => { this.goback()}} >
                    <Col span={1} style={{lineHeight : '30px', textAlign: 'center'}}>
                      <img src={backlogo} alt="backlogo" style={{width: '18px'}}></img>
                    </Col>
                </a>
                <Col span={4}>
                  <PageTitle TitleText={this.state.pageTitle}/>
                </Col>
              </Row>
              <Row style={{ margin: '5px' }}>
                <Col span={4}>
                  <Search style={{margin: '5px'}}
                    placeholder="Search"
                    allowClear
                    onSearch={value => this.handleSearchSubmit(value)}
                  />
                </Col>
                <Col span={5}>
                  <Dropdown overlay={this.searchBy}>
                      <Button style={{margin:'5px', marginLeft: '15px'}}>
                        <GetSearchText searchstate={this.state.searchBy}/><Icon type="down" />
                      </Button>
                  </Dropdown>
                </Col>
                <Col span={15} style={{ textAlign: 'right' }}>
                  <Popconfirm
                    title="Are you sure sign these client?"
                    onConfirm={() => this.signClient()}
                    okText="Yes"
                    cancelText="No"
                    >
                    <Button style={{margin : '5px', width : '135px'}}>
                      Sign
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
              <Row>
                <div>
                  <Table
                    rowSelection={rowSelection} 
                    dataSource={this.state.groupData}
                    columns={this.columndata}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    scroll={{ y: true }}
                    size="small"
                  />
                </div>
              </Row>
            </Card>
          </Row>
        </Fragment>
        )
    }
}



export default withRouter(addClientToDestinationGroup);
