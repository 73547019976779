import React, { Fragment } from 'react';
import 'antd/dist/antd.css';
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col, 
  message
} from 'antd';
import { RouterConstant } from '../../../enum/routerConstant';
import PageTitle from '../../pageTitle';
import backlogo from '../../../assets/icon/back-icon.svg'
import { Link } from 'react-router-dom';
import AddButton from '../../addButton';
import * as langService from '../../../enum/language/apiClient/changePassword';
import * as apiClientService from '../../../acessApi/SystemAdmin/apiClientService';
import {AccessChecker} from '../../../utility/AccessChecker'

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isFetching: false,
        confirmDirty: false,
        lastUpdated : null,
        pageTitle : null,
        autoCompleteResult: [],
        lang : {}
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.postData(values)
      }
    });
  };

  async postData(data) {
    message.destroy();
    message.loading("Changing your password", 0);
    this.setState({isFetching: true});
    const response = (await apiClientService.changePassword(data, this.props.match.params.id, this.state.lastUpdated));
    message.destroy();
    if (response.data.nexsoft_message.payload.data.error_code === "Oke") {
      message.success("Password has been changed");
      this.props.form.resetFields();
      this.setState({isFetching: false});
    } else {
      message.error("Error encountered from server");
      this.setState({isFetching: false});
    }
  }

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  goback(){
    window.history.back();
  }

  async componentDidMount(){
    this.getData()
    this.setState({
        lang : await langService.getLang(localStorage.getItem("language"))
    })
  }

  async getData(){
    const id = this.props.match.params.id;
    let ApiClientData = []
    ApiClientData = await apiClientService.getClientDetail(id)
    const data = await ApiClientData.data.nexsoft_message.payload.data.additional_info
    if (data.username != null && data.username != "") {
        this.setState({
            pageTitle : <PageTitle TitleText={"Change " + data.client_name + " password"} titleColor="black"/>,
            lastUpdated : data.record_meta_data.last_updated_at
        })
    }else{
        this.setState({
            pageTitle : <PageTitle TitleText="No Data" titleColor="black"/>
        })
    }
}

  render() {
    const { getFieldDecorator } = this.props.form;
    AccessChecker()
    const formItemLayout = {
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    return (
      <Row>
        <Card style={{borderRadius : '10px', boxShadow :'5px 5px 20px #888888', minHeight: '600px', margin:'15px'}}>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Row style={{margin : '5px'}}>
              <a onClick={() => { this.goback()}} >
                <Col span={1} style={{lineHeight : '30px', textAlign: 'center'}}>
                  <img src={backlogo} alt="logoutLogo" style={{width: '18px'}}></img>
                </Col>
              </a>
              <Col span={10} style={{lineHeight : '30px'}}>
                {this.state.pageTitle}
              </Col>
              <Col span={13} style={{textAlign : 'right'}}>
                <Button htmlType="submit" disabled={this.state.isFetching} style={{backgroundColor : '#5493FF', color: 'white', width: '135px', margin:"5px"}}>
                    Save
                </Button>
              </Col>
            </Row>
            <Row style={{fontSize : '16px', fontWeight: 'bold', margin :'10px'}}>
              <Form.Item label="Old Password" hasFeedback>
              {getFieldDecorator('oldpassword', {
                  rules: [
                  {
                      required: true,
                      message: 'Pasword format a-z A-Z 0-9 _- minimum of 6 character and maximum is 16 character',
                  },
                  ],
              })(<Input.Password />)}
              </Form.Item>
              <Form.Item label="Password" hasFeedback>
              {getFieldDecorator('password', {
                  rules: [
                  {
                      required: true,
                      message: 'Pasword format a-z A-Z 0-9 _- minimum of 6 character and maximum is 16 character',
                  },
                  {
                      validator: this.validateToNextPassword,
                  },
                  ],
              })(<Input.Password />)}
              </Form.Item>
              <Form.Item label="Confirm Password" hasFeedback>
              {getFieldDecorator('confirm', {
                  rules: [
                  {
                      required: true,
                      message: 'Those password are not match!',
                  },
                  {
                      validator: this.compareToFirstPassword,
                  },
                  ],
              })(<Input.Password onBlur={this.handleConfirmBlur} />)}
              </Form.Item>
            </Row>
          </Form>
        </Card>
      </Row>
    );
  }
}

const AddNewSource = Form.create({ name: 'add' })(RegistrationForm);

export default AddNewSource;
          