import React from 'react';
import { Input, Col, Row } from 'antd';
const { Search } = Input;

class SearchBar extends React.Component{

    render(){
        return (
            <Row>
                <Search
                    style={{ margin: this.props.margin }}
                    placeholder="Search"
                    onSearch={value => console.log(value)}
                />
            </Row>
        )
    }
}

export default SearchBar