import React, { Fragment } from 'react';
import 'antd/dist/antd.css';
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col, 
  message
} from 'antd';
import { RouterConstant } from '../../../enum/routerConstant';
import PageTitle from '../../pageTitle';
import backlogo from '../../../assets/icon/back-icon.svg'
import { Link } from 'react-router-dom';
import AddButton from '../../addButton';
import * as langService from '../../../enum/language/apiClient/edit';
import * as apiClientService from '../../../acessApi/SystemAdmin/apiClientService'
import {AccessChecker} from '../../../utility/AccessChecker'

class EditSourceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        pageTitle : null,
        username : null,
        principal : null,
        clientName : null,
        code : null,
        serverId : null,
        email : null,
        connInfo : null,
        lastUpdated : null,
        confirmDirty: false,
        address: null,
        phoneNumber :null,
        autoCompleteResult: [],
        lang : {}
    }
  }

  
    async componentDidMount(){
        this.getData()
        this.setState({
          lang : await langService.getLang(localStorage.getItem("language"))
        })
    }

    async getData(){
        const id = this.props.match.params.id;
        let ApiClientData = []
        ApiClientData = await apiClientService.getClientDetail(id)
        const data = await ApiClientData.data.nexsoft_message.payload.data.additional_info
        if (data.username != null && data.username != "") {
            this.setState({
                pageTitle : <PageTitle TitleText={data.client_name} titleColor="black"/>,
                id : data.id,
                username : data.username,
                principal : data.principal_id,
                clientName : data.client_name,
                // code : data.code,
                serverId : data.server_id,
                email : data.email,
                connInfo : data.connection_info,
                address: data.address,
                phoneNumber: data.phone_number,
                lastUpdated : data.record_meta_data.last_updated_at
            })
        }else{
            this.setState({
                pageTitle : <PageTitle TitleText={this.state.lang.noData} titleColor="black"/>
            })
        }
    }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.postCountry(values)
      }
    });
  };

  async postCountry(data) {
    message.destroy();
    message.loading(this.state.lang.saveApi, 0);
    const response = (await apiClientService.updateClientData(data));
    message.destroy();
    if (response.data.nexsoft_message.payload.data.error_code === "Oke") {
      message.success(this.state.lang.apiClientSaved);
    } else {
      message.error(this.state.lang.errorCreating);
    }
    this.getData()
}

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };


  handleWebsiteChange = value => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
    }
    this.setState({ autoCompleteResult });
  };

  goback(){
    window.history.back();
  }

  render() {

    const { getFieldDecorator } = this.props.form;
    AccessChecker()
    const formItemLayout = {
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }
    return (
      <Card style={{borderRadius : '10px', boxShadow :'5px 5px 20px #888888', minHeight: '600px'}}>
        <Row>
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Row style={{margin : '5px'}}>
                    <a onClick={() => { this.goback()}} >
                    <Col span={1} style={{lineHeight : '30px', textAlign: 'center'}}>
                        <img src={backlogo} alt="logoutLogo" style={{width: '18px'}}></img>
                    </Col>
                    </a>
                    <Col span={7} style={{lineHeight : '30px'}}>
                        {this.state.pageTitle}
                    </Col>
                    <Col span={16} style={{textAlign : 'right'}}>
                    <Button htmlType="submit" style={{backgroundColor : '#5493FF', color: 'white', width: '135px', margin:"5px"}}>
                        Save
                    </Button>
                    </Col>
                </Row>
                <Row style={{fontSize : '16px', fontWeight: 'bold', margin :'10px'}}>
                    <Form.Item label={this.state.lang.username} >
                    {getFieldDecorator('username', {
                        initialValue: this.state.username,
                        rules: [
                        {
                            required: true,
                            message: this.state.lang.usernameMessage,
                            pattern: new RegExp("^[a-z0-9_-]{3,16}$"),
                            
                        },
                        ],
                    })(<Input placeholder={this.state.username} />)}
                    </Form.Item>
                    <Form.Item label={this.state.lang.principal}>
                    {getFieldDecorator('principal', {
                        initialValue: this.state.principal,
                        rules: [
                        {
                            required: false,
                            message: 'Please input principal!',
                        },
                        ],
                    })(<Input placeholder={(this.state.principal )} disabled={true}/>)}
                    </Form.Item>
                    <Form.Item label={this.state.lang.clientName}>
                    {getFieldDecorator('clientName', {
                        initialValue: this.state.clientName,
                        rules: [
                        {
                            required: true,
                            message: this.state.lang.clientNameMessage,
                        },
                        ],
                    })(<Input placeholder={(this.state.clientName)}/>)}
                    </Form.Item>
                    {/* <Form.Item label={this.state.lang.clientCode}>
                    {getFieldDecorator('clientCode', {
                        initialValue: this.state.code,
                        rules: [
                        {
                            required: true,
                            message: this.state.lang.clientCodeMessage,
                        },
                        ],
                    })(<Input placeholder={(this.state.code )}/>)}
                    </Form.Item> */}
                    <Form.Item label={this.state.lang.email}>
                    {getFieldDecorator('email', {
                        initialValue: this.state.email,
                        rules: [
                        {
                            type: 'email',
                            message: this.state.lang.emailError,
                        },
                        {
                            required: true,
                            message: this.state.lang.emailMessage,
                        },
                        ],
                    })(<Input placeholder={(this.state.email )}/>)}
                    </Form.Item>
                    <Form.Item label={this.state.lang.connectionInfo}>
                    {getFieldDecorator('connInfo', {
                        initialValue: this.state.connInfo,
                        rules: [{ 
                        message: this.state.lang.connectionInfoMessage
                        }],
                    })(<Input placeholder={(this.state.connection_info )}/>)}
                    </Form.Item>
                    <Form.Item label={this.state.lang.address}>
                    {getFieldDecorator('address', {
                        initialValue: this.state.address,
                        rules: [{ 
                          message: this.state.lang.addressMessage
                        }],
                    })(<Input placeholder={(this.state.address )}/>)}
                    </Form.Item>
                    <Form.Item label={this.state.lang.phoneNumber}>
                    {getFieldDecorator('phoneNumber', {
                        initialValue: this.state.phoneNumber,
                        rules: [{ 
                          message: this.state.lang.phoneNumberMessage
                        }],
                    })(<Input placeholder={(this.state.phoneNumber )}/>)}
                    </Form.Item>
                    {getFieldDecorator('lastUpdated', {initialValue: this.state.lastUpdated})}
                    {getFieldDecorator('id', {initialValue: this.state.id})}
                </Row>
            </Form>
        </Row>
      </Card>
    );
  }
}

const EditApiClient = Form.create({ name: 'add' })(EditSourceForm);

export default EditApiClient;
          