import React from 'react';
import './index.css';
import logoutLogo from '../../assets/sidebar/logoutButton.svg'


class ApiServerClientLogo extends React.Component {
    render(){
        return(
            <li className="nav-item-container">
                <div className="icon-container">
                    <div className="api-client-icon">
                        <p className="logo-text">AC</p>
                    </div>
                </div>
                <span className="nav-text">
                    Api Server Client
                </span>
            </li>
        )
    }
}

class DestinationGroupLogo extends React.Component {
    render(){
        return(
            <li className="nav-item-container">
                <div className="icon-container">
                    <div className="destination-group-icon">
                        <p>DG</p>
                    </div>
                </div>
                <span className="nav-text">
                    Destination Group
                </span>
            </li>
        )
    } 
}


class DownloadLog extends React.Component {
    render(){
        return(
            <li className="nav-item-container">
                <div className="icon-container">
                    <div className="download-log-icon">
                        <p>DL</p>
                    </div>
                </div>
                <span className="nav-text">
                    Download Log
                </span>
            </li>
        )
    }
}

class UploadMonitoringLogo extends React.Component {
    render(){
        return(
            <li className="nav-item-container">
                <div className="icon-container">
                    <div className="upload-monitoring-icon">
                        <p>JP</p>
                    </div>
                </div>
                <span className="nav-text">
                    Job Process
                </span>
            </li>
        )
    }
}

class DataTypeLogo extends React.Component {
    render(){
        return(
            <li className="nav-item-container">
                <div className="icon-container">
                    <div className="data-type-icon">
                        <p>DT</p>
                    </div>
                </div>
                <span className="nav-text">
                    Data Type
                </span>
            </li>
        )
    }
}

class HousekeepingLogo extends React.Component {
    render(){
        return(
            <li className="nav-item-container">
                <div className="icon-container">
                    <div className="housekeeping-icon">
                        <p>HK</p>
                    </div>
                </div>
                <span className="nav-text">
                    Housekeeping
                </span>
            </li>
        )
    }
}


class ConfigurationLogo extends React.Component {
    render(){
        return(
            <li className="nav-item-container">
                <div className="icon-container">
                    <div className="configuration-icon">
                        <p>CF</p>
                    </div>
                </div>
                <span className="nav-text">
                    Configuration
                </span>
            </li>
        )
    }
}


class LogoutLogo extends React.Component {
    render(){
        return(
            <li className="nav-item-container">
                <div className="icon-container">
                    <div className="logout-icon">
                        <img src={logoutLogo} alt="logoutLogo"></img>
                    </div>
                </div>
                <span className="nav-text">
                    Logout
                </span>
            </li>
        )
    }
}

export { ApiServerClientLogo, DestinationGroupLogo, UploadMonitoringLogo, DataTypeLogo, LogoutLogo, ConfigurationLogo, HousekeepingLogo, DownloadLog };