import React, { Fragment } from 'react';
import { lang } from '../../../enum/language/searchBy';


class JobProcessText extends React.Component{
    render(){
        switch (this.props.searchstate) {
            case "filename":
                return <span>
                    {lang.searchBy} File Name
                </span>
                break;
            case "datatype":
                return <span>
                    {lang.searchBy} Data Type
                </span>
                break;
        }
    }
}

export default JobProcessText
    