const uuidv4 = require('uuid/v4');
const date = new Date();

export function createHeader(typeName) {
    const header = {
        "message_id": uuidv4().replace(/-/g, ''),
        "user_id":localStorage.getItem('user_name'),
        "password":localStorage.getItem('password'),
        "version":"1.0.0",
        "principal_id":localStorage.getItem('principal_id'),
        "timestamp": (date.toISOString()).replace(/Z/g, ''),
        "action":{
           "class_name":"MONITORING",
           "type_name":typeName
        }
    }
    return header
}