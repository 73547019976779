import React, { Fragment } from 'react';
import { Card, Col, Row, Pagination, Popover, Icon, Button, Table} from 'antd';
import AddButton from '../../addButton';
import { RouterConstant } from '../../../enum/routerConstant';
import SearchBar from '../../SearchBar';
import * as HousekeepingService from '../../../acessApi/SystemAdmin/housekeepingService'
import PageTitle from '../../pageTitle';
import './index.css';
import StatusIcon from '../../../common/housekeepingStatusIcon'
import { Link } from 'react-router-dom';
import {AccessChecker} from '../../../utility/AccessChecker'



class HistoryHousekeeping extends React.Component {  
  constructor(props) {
    super(props);
    this.columndata = [
      {
        width: 200,
        title: 'Date',
        dataIndex: 'housekeepingDate',
        key: 'housekeepingDate',
        render: text => <p>{text}</p>,
      },
      {
        width: 200,
        title: 'Rows Affected',
        dataIndex: 'rowsAffected',
        key: 'rowsAffected',
        render: text => <p>{text}</p>,
      },
      {
        width: 200,
        title: 'Housekeeping Type',
        dataIndex: 'housekeepingType',
        key: 'housekeepingType',
        render: text => <p>{text}</p>,
      },
      {
        width: 200,
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text =>< StatusIcon message={text} />,
      },
      // {
      //   width: 40,
      //   title: 'Action',
      //   key: 'action',
      //   render: (text, record) => (
      //     <span>
      //       <Popover content={"Job detail"}>
      //         <Link to = { RouterConstant.SYSADMIN.JOB.detail + '/' + record.key } key={record.key} >
      //           <Icon type="file-search" style={{margin : '5px', color : "#7a7a7a"}} />
      //         </Link>
      //       </Popover>
      //     </span>
      //   ),
      // },
    ];
    this.state = { 
      housekeepingData: null, 
      currentPage: 1, 
      totalPages: null, 
      housekeepingTableData: null, 
      totalData: null, 
      itemPerPage: 10, 
      dataCard : null 
    };
  }

  async getData(pageLimit, currentPage) {
    this.setState({ housekeepingData: await HousekeepingService.getHistoryHousekeepingList(currentPage, pageLimit) });
    this.mapData()
  }

  onPageChange = page => {
    this.setState({
      currentPage: page,
    });
    this.getData(this.state.itemPerPage, page)
  };

  onShowSizeChange = async (current, pageSize) => {
    await this.setState({
      itemPerPage: pageSize,
      currentPage : 1
    });
    this.getData(this.state.itemPerPage, this.state.currentPage)
  }

  async mapData(){
    let result = []
    if (this.state.housekeepingData.data.nexsoft_message.payload.data.additional_info != null) {
      const data = await this.state.housekeepingData.data.nexsoft_message.payload.data.additional_info
      data.map((results)=> {
        result.push({
          key: `${results.id}`,
          housekeepingDate: `${results.record_meta_data.created_at}`,
          rowsAffected: `${results.message}`,
          status: `${results.status}`,
          housekeepingType: `${results.housekeeping_type}`,
        });
      });
      this.setState({
        housekeepingTableData : result
      })
    }else{
      this.setState({
        housekeepingTableData : result
      })
    }
  }

  async componentDidMount(){
    await this.setState({
      totalData: await (await HousekeepingService.countHistoryHousekeeping()).data.nexsoft_message.payload.data.additional_info
    })
    await this.getData(this.state.itemPerPage, this.state.currentPage)
    this.mapData()
  }

  render(){
    AccessChecker()
    const pagination = {
      position: 'top', 
      defaultCurrent: this.state.currentPage,
      total: this.state.totalData, 
      onChange: this.onPageChange,
      showSizeChanger: true, 
      onShowSizeChange: this.onShowSizeChange, 
      pageSizeOptions: ["10", "20", "30", "40"],
      current : this.state.currentPage,
    };
    return(
      <Fragment >
        <Row>
          <Card style={{ borderRadius: '10px', boxShadow: '5px 5px 20px #888888', minHeight: '800px', margin: '15px' }}>
            <Row style={{ margin: '5px' }}>
              <Col>
                <PageTitle TitleText="History Housekeeping"/>
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: 'right' }}>
                <AddButton link={RouterConstant.SYSADMIN.HOUSEKEEPING.deletedHistory} text="Deleted History" />
              </Col>
            </Row>
            <Row style={{ marginTop: '20px'}}>
                <Table
                  dataSource={this.state.housekeepingTableData}
                  columns={this.columndata}
                  pagination={pagination}
                  onChange={this.handleTableChange}
                  scroll={{ y: true }}
                  size="small"
                />
            </Row>
          </Card>
        </Row>
      </Fragment>
    )   
  }
}

export default HistoryHousekeeping;
